import React from 'react';
import CustomButton from '../../../../components/CustomButton';

const Support = () => {
  return (
    <div>
      <h6 className="bolder-text">Help</h6>
      <p className="small-text co-darkgray mt-3">We can provide you with the help you need by either sending us a ticket or reading through our FAQs.</p>
      <CustomButton leftALign additionalClass="my-3" text="Send US A Ticket" backgroundColor="#3A6ED4" color="#fff" />
      <CustomButton leftALign additionalClass="my-3" text="Read Our FAQs" backgroundColor="#3A6ED4" color="#fff" />
    </div>
  );
};

export default Support;
