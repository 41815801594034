/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  DialogActions,
} from "@material-ui/core";
import icons from "../../../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../../../components/CustomButton";
import CustomTextArea from "../../../../../components/CustomTextArea";
import { SectionLoader } from "../../../../../components/PageLoader";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUpdated,
  declineApplication,
  getJobApplicants,
  hireApplicant,
  updateApplication,
} from "../../../../../redux/actions/insightActions";
import { useHistory, useParams } from "react-router-dom";
import { getJob } from "../../../../../redux/actions/jobActions";
import CustomInput from "../../../../../components/CustomInput";
import cogoToast from "cogo-toast";

const Reason = ({ setMessage }) => {
  const [reason, setReason] = useState("");

  const handleChangeReason = ({ target: { value } }) => {
    setReason(value);
    setMessage(value);
  };

  return (
    <div className="mb-3">
      <CustomTextArea
        placeholder="Enter Message"
        onChange={handleChangeReason}
        value={reason}
        label=""
        id=""
      />
      {/* <div className="d-flex">
        <button className="w-40 co-primary borderless bg-white">
          <b>Upload File</b>
        </button>
      </div> */}
    </div>
  );
};

Reason.propTypes = {
  setSuccess: func.isRequired,
};

const Applicants = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, stage } = useParams();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [currentApplicant, setCurrentApplicant] = useState();
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [salary, setSalary] = useState("");
  const [hiring, setHiring] = useState(false);
  const { applicants, updated } = useSelector(({ insight }) => insight);
  const { job } = useSelector((state) => state.job);
  const handleSetMessage = (message) => {
    setMessage(message);
  };

  useEffect(() => {
    let applStage;
    switch (stage) {
      case "consider":
        applStage = "1";
        break;
      case "test":
        applStage = "2";
        break;
      case "interview":
        applStage = "3";
        break;
      case "hired":
        applStage = "4";
        break;
      case "regret":
        applStage = "5";
        break;
      default:
        applStage = "*";
        break;
    }
    const getApplicants = async () => {
      setLoading(true);
      await getJobApplicants(dispatch, id, applStage);
      setLoading(false);
    };
    getApplicants();
  }, [job, stage]);

  useEffect(() => {
    getJob(dispatch, id);
  }, [dispatch, id]);

  const jobApplicants = applicants ? applicants["Applicants"] : [];
  // console.log("Job ID", applicants);

  const handleChange = (event) => {
    let val = parseInt(event.target.value);
    setValue(val);
    // console.log("val", value);
  };

  const handleReason = (reason) => {
    setReason(reason);
    // console.log("reason", reason);
  };

  const handleOpen = (applicant) => {
    setCurrentApplicant(applicant);
    setOpen(true);
  };

  const handleClose = () => {
    clearUpdated(dispatch);
    setSuccess(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const updateApplData = {
      job_id: parseInt(id, 10),
      stage_code: parseInt(value, 10),
      applicant_id: parseInt(currentApplicant.applicant_id, 10),
      reason: reason,
    };

    // console.log("updateApplData", updateApplData);

    await updateApplication(dispatch, updateApplData);
    if (updated) {
      handleClose();
    }
  };

  const rejectApplication = async () => {
    await declineApplication(dispatch, id, currentApplicant.applicant_id);
    if (updated) {
      handleClose();
    }
  };

  const handleHire = () => {
    const hire = async () => {
      if (job?.isToRefTimeLineOrToHireRecruter === "2" && !salary) {
        return cogoToast.error("Salary is required");
      }
      const data = {
        job_id: parseInt(id, 10),
        applicant_id: currentApplicant.applicant_id,
        message,
        salary,
        // isRecruiterJob: 0,
      };
      if (job?.isToRefTimeLineOrToHireRecruter !== "2") {
        delete data.salary;
      }
      setHiring(true);
      await hireApplicant(dispatch, data);
      if (updated) {
        handleClose();
      }
      setHiring(false);
      // setOpen(false);
    };
    hire();
  };

  return (
    <div className="position-relative w-100">
      <div className="d-flex justify-content-between filters mb-4">
        {job?.isToRefTimeLineOrToHireRecruter !== "2" && (
          <>
            <button
              onClick={() => history.push(`/insights/jobs/applicants/${id}`)}
            >
              All
            </button>
            <button
              onClick={() =>
                history.push(`/insights/jobs/applicants/${id}/consider`)
              }
            >
              consider
            </button>
            <button
              onClick={() =>
                history.push(`/insights/jobs/applicants/${id}/test`)
              }
            >
              test
            </button>
            <button
              onClick={() =>
                history.push(`/insights/jobs/applicants/${id}/interview`)
              }
            >
              interview
            </button>
            <button
              onClick={() =>
                history.push(`/insights/jobs/applicants/${id}/regret`)
              }
            >
              regret
            </button>
          </>
        )}
      </div>
      {loading && <SectionLoader />}
      {!loading && !jobApplicants && (
        <div className="d-flex align-items-center cursor-pointer justify-content-center mb-3">
          <p className="text-center">No applicants</p>
        </div>
      )}

      {!loading &&
        jobApplicants &&
        jobApplicants[0] &&
        jobApplicants.map((applicant) => (
          <div
            key={applicant.id}
            onClick={() => handleOpen(applicant)}
            className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
          >
            <span className="d-flex align-items-center">
              <img width="50" src={applicant.image_url} alt="avatar" />
              <span className="d-flex flex-column ml-3">
                <h6 className="mb-0">
                  {applicant.first_name} {applicant.last_name}
                </h6>
                <span className="co-gray small-text">
                  @{applicant.username}
                </span>
              </span>
            </span>
            <span>
              <CustomButton
                text={
                  job?.isToRefTimeLineOrToHireRecruter === "2" ? "Hire" : "Move"
                }
                alt="Move"
                type="button"
                backgroundColor="white"
                borderColor="var(--primary-color)"
                additionalClass={"px-4"}
              ></CustomButton>
            </span>
          </div>
        ))}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "40rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {currentApplicant && (
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle id="form-dialog-title">
              {" "}
              {!success && (
                <span className="w-100 d-flex align-items-center">
                  <img
                    width="50"
                    src={currentApplicant.image_url}
                    alt="avatar"
                  />
                  <span className="d-flex flex-column ml-3">
                    <h6 className="mb-0">
                      {currentApplicant.first_name} {currentApplicant.last_name}
                    </h6>
                    <CustomButton
                      handleClick={() =>
                        history.push(
                          `/profile/${currentApplicant.applicant_id}`
                        )
                      }
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="View Profile"
                      additionalClass="mt-2 p-1 small-text"
                    />
                  </span>
                </span>
              )}
            </DialogTitle>
            <div className="close-button">
              <IconButton onClick={handleClose}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
        )}
        {/* {(declined || updated) && ( */}
        {/* <DialogContent>
          <div className="d-flex flex-column justify-content-center align-items-center mx-auto my-5">
            <svg width="119" height="119" className="rounded-circle">
              <use href={`${icons}#checkmark`}></use>
            </svg>
            <p className="co-green mt-2 small-text">
              Successfully {updated ? "updated" : "declined"}
            </p>
          </div>
        </DialogContent> */}
        {/* )}
        {!declined && !updated && ( */}
        <DialogContent>
          <RadioGroup
            aria-label="change statue"
            name="status"
            value={value}
            onChange={handleChange}
          >
            {job?.isToRefTimeLineOrToHireRecruter !== "2" && (
              <>
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Consider"
                />
                {value === 1 && (
                  <Reason
                    key={1}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
                {/* <Reason setSuccess={setSuccess} /> */}
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="Test"
                />
                {value === 2 && (
                  <Reason
                    key={2}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
                <FormControlLabel
                  value={3}
                  control={<Radio color="primary" />}
                  label="Interview"
                />
                {value === 3 && (
                  <Reason
                    key={3}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
              </>
            )}
            <FormControlLabel
              value={4}
              control={<Radio color="primary" />}
              label="Hire"
            />
            {value === 4 && (
              <>
                {job?.isToRefTimeLineOrToHireRecruter === "2" && (
                  <CustomInput
                    type="text"
                    onChange={(e) => setSalary(e.target.value)}
                    name="salary"
                    value={salary}
                    placeholder=""
                    label="Salary"
                    id="salary"
                  />
                )}
                <Reason
                  key={4}
                  setSuccess={setSuccess}
                  handleReason={handleReason}
                  setMessage={handleSetMessage}
                />
              </>
            )}
          </RadioGroup>
        </DialogContent>
        {/* )} */}
        {!success && (
          <DialogActions className="mt-5">
            <span className="w-100 mr-4">
              <span className="d-flex">
                <CustomButton
                  type="button"
                  handleClick={rejectApplication}
                  backgroundColor="#FF5964"
                  color="#fff"
                  text="Decline Application"
                  additionalClass="mx-3 mt-2"
                />
                {value !== 4 && (
                  <CustomButton
                    type="button"
                    handleClick={handleSubmit}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Move Applicant"
                    additionalClass="mx-3 mt-2"
                  />
                )}
                {value == 4 && (
                  <CustomButton
                    type="button"
                    handleClick={handleHire}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Hire Applicant"
                    additionalClass="mx-3 mt-2"
                    loading={hiring}
                  />
                )}
              </span>
            </span>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Applicants;
