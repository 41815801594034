/* eslint-disable indent */
import {
  CREATE_JOB,
  DELETE_SAVED_JOBS,
  GET_ALL_JOBS,
  GET_ALL_RECOMMENDATION_JOBS,
  GET_OFFERS_JOBS,
  GET_APPLIED,
  GET_JOB,
  GET_JOB_LEVELS,
  GET_MY_JOBS,
  DELETE_JOBS,
  GET_SAVED_JOBS,
  RESET_JOB_CREATED,
  SAVE_JOB,
  REPORT_JOB,
  SEARCH_JOBS,
  LIKE_UNLIKE_JOB,
  GET_REFERRAL_JOBS,
  RECRUIT_REQUESTS,
  RESET_JOBS,
  GET_ADMIN_JOBS,
  APPLY_TO_RECRUITER_JOB,
  GET_BUSINESS_JOB,
  RECRUIT_REQUESTS_BY_JOBS,
} from "../actionTypes";

const initialState = {
  jobsData: {
    jobs: [],
    count: 0,
  },
  savedData: {
    saved: [],
    count: 0,
  },
  jobApplications: [],
  jobOffers: {
    jobs: [],
    total_applicants: 0,
  },
  jobLevels: [],
  myJobs: [],
  referralJobs: [],
  jobApplied: {},
  searchedTerm: "",
  recruitRequests: [],
  recruitRequestsByJobs: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case RECRUIT_REQUESTS_BY_JOBS:
      return {
        ...state,
        recruitRequestsByJobs: payload,
      };
    case RECRUIT_REQUESTS:
      return {
        ...state,
        recruitRequests: payload,
      };
    case GET_ALL_JOBS:
      return {
        ...state,
        jobsData: {
          // jobs: [...state.jobsData.jobs, ...payload["0"]],
          jobs: payload["0"],
          count: payload["total_item_count"],
        },
      };
      case GET_ALL_RECOMMENDATION_JOBS:
        return {
          ...state,
          jobsData: {
            // jobs: [...state.jobsData.jobs, ...payload["0"]],
            jobs: payload["0"],
            count: payload["total_item_count"],
          },
        };
    case GET_OFFERS_JOBS:
      // console.log("Payload", payload);
      return {
        ...state,
        jobOffers: {
          jobs: [...payload["jobs"]],
          total_applicants: payload["total_applicants"],
        },
      };
    case GET_JOB:
      return {
        ...state,
        job: payload,
        editjob:payload
      };
    case GET_BUSINESS_JOB:
      return {
        ...state,
        job: payload,
      };
    case GET_MY_JOBS:
      return {
        ...state,
        myJobs: payload["0"],
      };
    case GET_JOB_LEVELS:
      return {
        ...state,
        jobLevels: payload,
      };
    case SEARCH_JOBS:
      return {
        ...state,
        searchedTerm: payload.term,
        jobs: payload.data,
      };
    case GET_APPLIED:
      return {
        ...state,
        jobApplications: payload,
      };
    case SAVE_JOB: {
      const newJobsArray = state.jobs.map((job) => {
        if (job.id === payload.job_id) {
          job.isSaved = 1;
        }
        return job;
      });
      return {
        ...state,
        jobs: newJobsArray,
      };
    }
    case REPORT_JOB: {
      return {
        ...state,
      };
    }
    case GET_SAVED_JOBS:
      return {
        ...state,
        savedData: {
          // saved: [...state.savedData.saved, ...payload["0"]],
          saved: [...payload["0"]],
          count: payload["total_item_count"],
        },
      };
    case DELETE_SAVED_JOBS: {
      // console.log("SavedData", state.savedData);
      const jobsArray = state.savedData.saved.filter(
        (job) => job.id !== payload.job_id
      );
      return {
        ...state,
        saved: jobsArray,
      };
    }
    case DELETE_JOBS: {
     
      return {
        ...state
       
      };
    }
    case CREATE_JOB: {
      const jobArr = [...[payload], ...state.myJobs];
      return {
        ...state,
        myJobs: jobArr,
        jobCreated: true,
      };
    }
    case LIKE_UNLIKE_JOB: {
      // const jobArr = [...[payload], ...state.myJobs];
      return {
        ...state,
        jobLiked: true,
        jobId: payload.job_id,
      };
    }
    case GET_REFERRAL_JOBS:
      return {
        ...state,
        referralJobs: payload.jobs,
      };
    case RESET_JOB_CREATED:
      return {
        ...state,
        jobCreated: false,
      };
    case RESET_JOBS:
      return {
        ...state,
        jobsData: {
          jobs: [],
          count: 0,
        },
      };
    case GET_ADMIN_JOBS:
      return {
        ...state,
        adminJobs: payload,
      };
    case APPLY_TO_RECRUITER_JOB: {
      // const jobArr = [...[payload], ...state.myJobs];
      return {
        ...state,
        jobApplied: payload,
        jobCreated: true,
      };
    }
    default:
      return state;
  }
};
