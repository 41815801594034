import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import icons from "../assets/icons/icon-collection.svg";
import Menu from "./Menu";
import BusinessMenu from "./Menu/BusinessMenu";
import ToolbarMenu from "./ToolbarMenu";
import { bool, node } from "prop-types";
import "./index.scss";
import LeaderBoard from "./LeaderBoard";
import ExpertBoard from "./ExpertBoard";
import { useLocation, useHistory } from "react-router-dom";

import {
  createJobAction,
  getJobLevelsAction,
  clearJobCreated
} from "../redux/actions/jobActions";
import { getIndustryAction } from "../redux/actions/industryActions";
import { getAllSkillsAction } from "../redux/actions/skillsAction";

import CustomButton from "../components/CustomButton";
import Plus from "../assets/icons/Plus.svg";
import AddNewPost from "./NewPost";
import BusinessAddNewPost from "../Pages/Business/BusinessNewPost";
import { useDispatch, useSelector } from "react-redux";
import { DemoPointer } from "../demo";
import { demoAction } from "../redux/actions/demoActions";
import ReactTooltip from 'react-tooltip';
import SlidingPane from "react-sliding-pane";
import JobDetails from "./NewPost/JobDetails/";
import AddDocuments from "./NewPost/JobDocuments/";
import { openRecruiterModal } from "../redux/actions/demoActions";

 
const drawerWidth = 240;

const isBusinessUser = () => {
  const accountType = localStorage.getItem("accountType");
  return accountType === "business";
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - 73px)`,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - 60px)`,
    },
    boxShadow: "2px 2px #eeeeee",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  black: {
    background: theme.palette.warning.main,
  },
  blue: {
    background: theme.palette.primary.main,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    background: "#ccc",
  },
}));

const Layout = ({ children, recruiter }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [newPost, setNewPost] = useState(false);
  const [success, setSuccess] = useState(false);
  const [canPost, setCanPost] = useState(true);

  const [industryOptions, setIndustryOptions] = useState({});
  const [section, setSection] = useState("details");
  const [levelOptions, setLevelOptions] = useState({});
  const [skillOptions, setSkillOptions] = useState({});
  const [isJobCreated, setIsJobCreated] = useState(false);

  const { pathname } = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [newJob, setNewJob] = useState({
    campany_name: "",
    industry: "0",
    about_company: "",
    no_of_staff: "0",
    job_level: "0",
    role_title: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    location: "",
    additional_files: [],
    isToRefTimeLineOrToHireRecruter: 1,
    isCompanyRepresentative: "1",
    salary: "",
    duration: "",
    canRecruiterRequest: "0",
    country:0
  });
  const { industry, skill, job } = useSelector((store) => store);
  const { allSkills } = skill;
  const { industries } = industry;
  const { jobLevels, jobCreated } = job;


 
    const handleWindowSizeChange = () => 
    {
      setWidth(window.innerWidth);
    }

    useEffect(() => {
       if(width <= 768){
        setOpen(false);
       }
       else{
        setOpen(true);

       }

   }, [width]);

   
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
     }, []);

    const {
      userData: { user_type },
    } = useSelector((store) => store.auth );

    useEffect(() => {
      if (user_type === "1") {
        setCanPost(true);
      } else {
        setCanPost(false);
      }
    }, [dispatch]);

    const handleNewPost = () => {
      clearJobCreated(dispatch);
      setNewPost(true);
     // setOpen(false);
    };

  const handleClose = () => {
    setSuccess(false);
    setNewPost(false);
  };

  const handleDrawerOpen = () => 
  {
    setOpen(!open);
  };
  
  const displayMenu = () => {
    return isBusinessUser() ? (
      <BusinessMenu open={open} toolbar={classes.toolbar} />
    ) : (
      <Menu open={open} toolbar={classes.toolbar} />
    );
  };
  
  useEffect(() => {
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
    ]);
  }, [dispatch]);
  
  useEffect(() => {
    const options = {};
    industries && industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
  }, [industries]);

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    setSkillOptions(allSkills);
    // setSkillOptions(options);
  }, [allSkills]);

  
  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

  const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  };

  const sendToRecruiter = async (e) => {
    e.preventDefault();
    openRecruiterModal(dispatch);

    /*
    e.preventDefault();
    if (
      !newJob.campany_name ||
      !newJob.industry ||
      !newJob.about_company ||
      !newJob.no_of_staff ||
      !newJob.job_level ||
      !newJob.role_title ||
      !newJob.job_type ||
      !newJob.reports_to ||
      !newJob.applicant_benefit ||
      !newJob.skills_required ||
      !newJob.location ||
      !newJob.salary ||
      !newJob.duration
    ) {
      return cogoToast.error("Please fill all fields");
    }
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );
    const canRecruiterRequest = parseInt(newJob.canRecruiterRequest, 10);
    const jobToSend = {
      ...newJob,
      ...{ job_level, isCompanyRepresentative, canRecruiterRequest },
    };

    history.push({
      pathname: "/recruiter/hire",
      state: { jobToSend },
    });
    closeDialog();


    */
  };
  const updateSelectedCountryID = (cid) => {
     setNewJob({ ...newJob, ...{ country: cid } });
  };

  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };
  const [linksObject, setLinksObject] = useState([]);
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };
  const [value, setValue] = useState(0);
  
  const handleSubmit = async (e) => {
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );
    
 
    e.preventDefault();
    // if (section === "documents") {
    //   setSection("details");
    // } else {
    await createJobAction(dispatch, {
      ...newJob,
      ...{
        job_level,
        isCompanyRepresentative,
        canRecruiterRequest: parseInt(newJob.canRecruiterRequest),
      },
    });
    setIsJobCreated(true);
     // }
  };

  return (
    <div className="wrapper d-flex">
     <ReactTooltip/>
      
      <AppBar
        color="secondary"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <svg width="18" height="16">
              <use href={`${icons}#menubutton`}></use>
            </svg>
          </IconButton>
          <ToolbarMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.blue]: !recruiter,
          [classes.black]: recruiter,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.blue]: !recruiter,
            [classes.black]: recruiter,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {displayMenu()}
      </Drawer>
      <main className={classes.content}>
        <div className="mt-5 d-flex">

       
          <div className="main-content mr-md-4">{children}</div>
      
          <div className="d-none d-md-block w-25">
            {canPost && (
              
                <CustomButton
                  icon={Plus}
                  backgroundColor="#3A6ED4"

                  
                  color="#fff"
                  alt="add post"
                  text="Post a New Job"
                  additionalClass="my-3 joyride-post-job"
                  handleClick={(e) => {
                    e.preventDefault();
                    const state = {
                      ["Post a Job"]: false,
                      ["Click on Post a Job"]: false,
                      ["Post to referral timeline"]: true,
                      ["Don't forget to add your files and links"]: true,
                    };
                    demoAction(dispatch, state);
                    handleNewPost(e);
                  }}
                />
                 
            )}
            {!canPost && <div style={{ height: "60px" }}></div>}
            {isBusinessUser() ? (
              <BusinessAddNewPost
                open={newPost}
                handleClose={handleClose}
                setSuccess={setSuccess}
                success={success}
              />
            ) : (
              <AddNewPost
                open={false}
                handleClose={handleClose}
                setSuccess={setSuccess}
                success={success}
              />
            )}
          {  
       (pathname.includes("league/list") || pathname.includes("league/points")) ?    
           <></>
            :
            
            <div>
            {}
            <LeaderBoard />
            <br/>
            <ExpertBoard/>
      {/*   
      !isBusinessUser ?    */}
      <div>
            
        <SlidingPane
        className=""
        overlayClassName="some-custom-overlay-class"
        isOpen={newPost}
        title="New Post"
        subtitle="Creat a New Job Post"
        width="90%"
        height="10%"
        from={'right'}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          setNewPost(false)
         // setOpen(false);
        }}
      >

                <JobDetails
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  updateSelectedCountryID={updateSelectedCountryID}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                  setNewJob ={setNewJob}
                  newJob={newJob}
                />

              <AddDocuments
                  job={newJob}
                  addDocLinkObject={(link) => addDocLinkObject(link)}
                  addDocLink={(link) => addDocLink(link)}
                  linksObject={linksObject}
                />

            <div className="row" style={{marginTop:'10%'}}>  
            <div className="col-12 col-md-6">
     
            <CustomButton
              type="button"
              backgroundColor="#3A6ED4"

              color="#fff"
              text={"Send Job to Recruiter"}
              handleClick={sendToRecruiter}
              additionalClass="mx-3 mt-2"
           
            />
            </div>
             
            <div className="col-12 col-md-6">

              <CustomButton
                type="submit"
            
                backgroundColor="#41B883"
                handleClick={(e) => {
                  e.preventDefault();
                  const state = {
                    ["Post a Job"]: false,
                    ["Click on Post a Job"]: false,
                    ["Don't forget to add your files and links"]: false,
                    ["Post to referral timeline"]: true,
                    ["Fill In Details"]: false,
                  };
                  demoAction(dispatch, state);
                  handleSubmit(e);
                }}
                color="#fff"
                text={"Publish Job to Referral"}
                additionalClass="mx-3 mt-2"
              />
              </div>
            </div>  
       
     </SlidingPane>

     </div>
     {/* :
      <></>
     */}
            </div>
        } 
     
      
          </div>
        </div>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: node.isRequired,
  recruiter: bool,
};

Layout.defaultProps = {
  recruiter: false,
};

export default Layout;
