import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func } from "prop-types";
import { IconButton } from "@material-ui/core";
import icons from "../../../assets/icons/icon-collection.svg";
import CustomButton from "../../../components/CustomButton";
import {
  createBusinessJobAction,
  getSubsidiaries,
} from "../../../redux/actions/businessActions";
import { getJobLevelsAction } from "../../../redux/actions/jobActions";
import { getIndustryAction } from "../../../redux/actions/industryActions";
import { getAllSkillsAction } from "../../../redux/actions/skillsAction";
import JobDetails from "./JobDetails";
import AddDocuments from "./JobDocuments";

const BusinessAddNewPost = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [industryOptions, setIndustryOptions] = useState({});
  const [section, setSection] = useState("details");
  const [levelOptions, setLevelOptions] = useState({});
  const [skillOptions, setSkillOptions] = useState({});
  const [subsidiaryOptions, setSubsidiaryOptions] = useState({});
  const [linksObject, setLinksObject] = useState([]);
  const [jobCreated, setJobCreated] = useState(false);
  const [newJob, setNewJob] = useState({
    company_id: "0",
    subsidiary_id: "0",
    location: "",
    industry: "0",
    department: "",
    job_level: "0",
    role_title: "",
    about_role: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    additional_files: [],
  });
  const [loading, setLoading] = useState(false);
  const { industry, skill, job, business } = useSelector((store) => store);
  const { allSkills } = skill;
  const { industries } = industry;
  const { jobLevels } = job;
  const {
    userData: { account_type, company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };

  useEffect(() => {
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
      getSubsidiaries(dispatch, company_id),
    ]);
  }, [dispatch]);

  useEffect(() => {
    const options = {};
    industries &&  industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
    if (account_type === "business") {
      setNewJob({
        ...newJob,
        industry: industries &&  industries[company.industry]?.title || "",
      });
    }
  }, [industries]);

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    // setSkillOptions(options);
    setSkillOptions(allSkills);
  }, [allSkills]);

  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

  useEffect(() => {
    const options = {};
    const subsidiaries = business ? business.subsidiaries : [];
    subsidiaries &&
      subsidiaries.forEach(
        (subsidiary) => (options[subsidiary.id] = subsidiary.subsidiary_name)
      );
    setSubsidiaryOptions(options);
  }, [business]);

  const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  };

  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };

  const handleSubmit = (e) => {
    const job_level = parseInt(newJob.job_level, 10);
    setLoading(true);

    e.preventDefault();
    // console.log("newJob", newJob);
    if (section === "documents") {
      setSection("details");
    }
    createBusinessJobAction(
      (action) => {
        setJobCreated(true);
        dispatch(action);
      },
      {
        ...newJob,
        ...{ job_level, company_id: parseInt(company_id, 10) },
      }
    );

    setLoading(false);
  };

  const departmentOptions = {
    none: "None",
    engineering: "Engineering",
    customer_service: "Customer Service",
    information_technology: "Information and Technology",
    human_resources: "Human Resources",
    marketing: "Marketing",
    operations: "Operations",
    finance: "Finance",
    public_relations: "Public Relations",
    general_management: "General Management",
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "35rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      > 
        <div className="d-flex justify-content-between align-items-centter">
          <DialogTitle id="form-dialog-title">
            {" "}
            {!jobCreated && <>Post a New Job</>}
          </DialogTitle>
          <div className="close-button">
            <IconButton onClick={handleClose}>
              <svg width="30" height="30" className="rounded-circle">
                <use href={`${icons}#close`}></use>
              </svg>
            </IconButton>
          </div>
        </div>
        {jobCreated && (
          <DialogContent>
            <div className="d-flex flex-column justify-content-center align-items-center mx-auto my-5">
              <svg width="119" height="119" className="rounded-circle">
                <use href={`${icons}#checkmark`}></use>
              </svg>
              <p className="co-green mt-2 small-text">
                Job successfully Published
              </p>
              <CustomButton
                backgroundColor="#3A6ED4"
                color="#fff"
                text="Post a New Job"
                handleClick={() => setJobCreated(false)}
              />
            </div>
          </DialogContent>
        )}
        {!jobCreated && (
          <DialogContent>
            <form onSubmit={handleSubmit}>
              {
              section === "details" && (
                <JobDetails
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                  subsidiaryOptions={subsidiaryOptions}
                  departmentOptions={departmentOptions}
                />
              )}
              
              {
               section === "documents" && (
                <AddDocuments
                  job={newJob}
                  addDocLinkObject={(link) => addDocLinkObject(link)}
                  addDocLink={(link) => addDocLink(link)}
                  linksObject={linksObject}
                />
              )}
            </form>
          </DialogContent>
        )}
        {!jobCreated && (
          <DialogActions>
            <CustomButton
              type="button"
              backgroundColor="#3A6ED4"
              color="#fff"
              text={section === "documents" ? "Job Details" : "Upload File"}
              handleClick={() =>
                section === "documents"
                  ? setSection("details")
                  : setSection("documents")
              }
              additionalClass="mx-3 mt-2"
            />
            <CustomButton
              type="submit"
              backgroundColor="#41B883"
              handleClick={handleSubmit}
              color="#fff"
              loading={loading}
              text={"Publish"}
              additionalClass="mx-3 mt-2"
            />
          </DialogActions>
        )} 
      </Dialog>
    </div>
  );
};

BusinessAddNewPost.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
};

export default BusinessAddNewPost;
