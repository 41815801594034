import React, { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import { Link, useLocation } from "react-router-dom";
import CustomInput from "../../../../components/CustomInput";
import Lock from "../../../../assets/icons/Lock.svg";
// import WorkImage from "../../../../assets/images/workimage.png";
import { isValidEmail } from "../../../../utils/helpers";
import "./index.scss";
import {
  forgotPassword,
  resetPassword,
} from "../../../../redux/actions/authActions";
import { AUTH_LOADING, STOP_AUTH_LOADING } from "../../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import WBtext from "../../../../assets/icons/WBtext.svg";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [sent, setSent] = useState(false);
  const { loading } = useSelector((store) => store.auth);
  const [state, setState] = useState({
    email: "",
  });
  const [reset, setReset] = useState({
    code: "",
    password: "",
  });

  const handleChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    setState({ ...state, ...newValue });
  };

  const handleResetChange = ({ target: { id, value } }) => {
    const newValue = {};
    newValue[id] = value;
    setReset({ ...reset, ...newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("I'm clicked");
    dispatch({ type: AUTH_LOADING });
    Promise.resolve(forgotPassword(dispatch, state))
      .then(() => {
        setState({ email: "" });
        setSent(true);
      })
      .finally(() => {
        // setSent(true);
        dispatch({ type: STOP_AUTH_LOADING });
      });
  };

  const resetForm = (e) => {
    e.preventDefault();
    // console.log("reset form clicked");
    dispatch({ type: AUTH_LOADING });
    Promise.resolve(resetPassword(dispatch, reset))
      .then(() => {
        setReset({ code: "", password: "" });
        // setSent(true);
      })
      .finally(() => {
        // setSent(true);
        dispatch({ type: STOP_AUTH_LOADING });
      });
  };

  return (
    <div
      className="auth"
      style={{
        background: "#3A6ED4",
      }}
    >
      <div className="wrapper">
        <div className="row">
          <div className="col-6 p-5 d-none d-md-flex justify-content-end">
            {/* <img className="work-image" src={WorkImage} alt="working" /> */}
            <img className="work-image" src={WBtext} alt="working" />
          </div>
          <div className="col-12 col-md-6 p-5">
            <div className="auth-form w-100">
              <div className="d-flex justify-content-center mb-3">
                <img className="w-40" src={Lock} alt="lock" />
              </div>
              {pathname.includes("reset") && (
                <div>
                  <h4 className="text-center mt-2">Reset Your Password</h4>
                  <form onSubmit={resetForm}>
                    <div className="my-5">
                      <CustomInput
                        onChange={handleResetChange}
                        type="text"
                        label="Reset Code"
                        id="code"
                        value={reset.code}
                        required
                      />
                    </div>

                    <CustomInput
                      onChange={handleResetChange}
                      type="password"
                      label="New Password"
                      id="password"
                      value={reset.password}
                      required
                    />

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="Set New Password"
                      additionalClass="mt-4 mb-5"
                      type="submit"
                    />
                  </form>
                  <p className="pt-4 pb-5 small-text text-center">
                    Go back to <Link to="/signin">Login</Link>{" "}
                  </p>
                </div>
              )}
              {!pathname.includes("reset") && sent && (
                <>
                  <h4 className="text-center mt-5">Recover Your Password</h4>
                  <div className={sent ? "pb-5" : ""}>
                    <CustomButton
                      backgroundColor="#41B883"
                      color="#fff"
                      text="Sent"
                      additionalClass="mt-3 mb-2"
                    />
                    <p className="pb-5 small-text text-center">
                      Check your email for confirmation{" "}
                    </p>
                  </div>
                </>
              )}
              {!pathname.includes("reset") && !sent && (
                <div>
                  <h4 className="text-center mt-5">Recover Your Password</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="my-5">
                      <CustomInput
                        onChange={handleChange}
                        type="text"
                        label="Email"
                        id="email"
                        value={state.email}
                        required
                      />
                    </div>

                    <CustomButton
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="Recover Password"
                      additionalClass="mt-4 mb-5"
                      type="submit"
                      loading={loading}
                      disabled={!isValidEmail(state.email) || !state.email}
                    />
                  </form>
                  <p className="pt-4 pb-5 small-text text-center">
                    Go back to <Link to="/signin">Login</Link>{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
