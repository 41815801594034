import cogoToast from "cogo-toast";
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../../../components/CustomInput";
import LinkButton from "../../../../../components/LinkButton";
import paperclip from "../../../../../assets/icons/paperclip.svg";
import { setResume } from "../../../../../redux/actions/profileActions";
import { uploadFile } from "../../../../../utils/helpers";
import { getProfileAction } from "../../../../../redux/actions/profileActions";
import { useParams } from "react-router-dom";
import { jobReferralLinkClicked } from "../../../../../redux/actions/businessActions";

const Apply = () => {
  const dispatch = useDispatch();
  const uploadInputRef = useRef(null);
  const { profile } = useSelector((store) => store);
  const { profileData } = profile;
  const [isUploading, setIsUploading] = useState(false);
  const { auth } = useSelector((store) => store);
  const [uploadedResume, setUploadedResume] = useState({});
  const handleRefClick = () => {
    uploadInputRef.current.click();
  };
  const { refLink, jobId, recruiterId } = useParams();

  useEffect(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (recruiterId) {
      jobReferralLinkClicked({
        invited_by_code: refLink,
        job_id: parseInt(jobId),
      });
    }
  }, [refLink]);

  const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
      setIsUploading(true);
      Promise.resolve(
        uploadFile(
          files[0],
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            cogoToast.success("Resume Uploaded", {
              hideAfter: 4,
              position: "top-center",
            });
            setUploadedResume({ link: data, name: files[0].name });
            setResume(dispatch, { userId: auth.userData.id, link: data });
          } else {
            cogoToast.error("Unsuccessful upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };

  return (
    <form className="mt-5 pt-5">
      <CustomInput
        isDisabled
        value={`${auth.userData.first_name} ${auth.userData.last_name}`}
        type="text"
        label="Full Name"
        id="name"
      />
      <CustomInput
        isDisabled
        value={auth.userData.email}
        type="text"
        label="Email Address"
        id="email"
      />

      <CustomInput
        isDisabled
        type="text"
        label="Phone Number"
        id="name"
        value={auth.userData.phone}
      />

      <div className="w-100">
        {!auth.userData.resume && (
          <div className="co-lightred">You have not uploaded your resume</div>
        )}
        {auth.userData.resume && (
          <>
            <img
              src={paperclip}
              alt="attachment"
              width="12px"
              style={{ marginRight: "5px" }}
            />
            <LinkButton
              label={
                uploadedResume.name ??
                profileData.resumeName ??
                "Your Uploaded CV"
              }
              onClick={() => window.open(`${auth.userData.resume}`, "_blank")}
            />
          </>
        )}
        <div className="my-4 d-flex">
          <div className="w-50 mr-1">
            <input
              ref={uploadInputRef}
              type="file"
              onChange={handleChangeUpload}
              style={{ display: "none" }}
            />
            <LinkButton
              label="Upload New CV (2MB)"
              onClick={handleRefClick}
              loading={isUploading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Apply;
