import React, { useState } from "react";
import { IconButton, Divider, Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import icons from "../../assets/icons/icon-collection.svg";
import smallpholder from "../../assets/icons/small-placeholder.svg";
import placeholder from "../../assets/icons/placeholder.svg";
import Notifications from "./Notifications";
import { Link, useHistory } from "react-router-dom";
import { logoutAction } from "../../redux/actions/authActions";
import { ternaryResolver } from "../../utils/helpers";
import CustomInput from "../../components/CustomInput";
import {
  clearJobCreated,
  searchJobAction,
} from "../../redux/actions/jobActions";
import { searchJobAction as businessSearchJobAction } from "../../redux/actions/businessActions";
import AddNewPost from "../NewPost";

const ToolbarMenu = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const { userData } = useSelector((store) => store.auth);
  const { first_name, last_name, image_url } = userData;
  const resolvedName = ternaryResolver(
    first_name && last_name,
    `${first_name} ${last_name}`,
    "Hello User"
  );
  const history = useHistory();
  const [newPost, setNewPost] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNewPost = () => {
    clearJobCreated(dispatch);
    setNewPost(true);
  };

  const handleNewPostClose = () => {
    setSuccess(false);
    setNewPost(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isBusinessUser = () => {
    const accountType = localStorage.getItem("accountType");
    return accountType === "business";
  };
  
  const handleSearch = () => {
    if (searchTerm) {
      if (isBusinessUser()) {
        return businessSearchJobAction(dispatch, searchTerm, () =>
          history.push("/home")
        );
      }
      return searchJobAction(dispatch, searchTerm, () => history.push("/home"));
    }
    return setShowSearchBar(!showSearchBar);
  };

  return (
    <div className="w-100 d-flex align-items-center justify-content-end">
      {showSearchBar && (
        <span className="w-50 pb-1 mb-2 search-container">
          <CustomInput
            placeholder="Search"
            additionalClass="dark-border"
            id="search-job"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
      )}

      <IconButton onClick={handleSearch}>
        <svg width="18" height="16">
          <use href={`${icons}#search`}></use>
        </svg>
      </IconButton>
      <IconButton onClick={(e) => setNotificationsAnchorEl(e.currentTarget)}>
        <svg width="16" height="18">
          <use href={`${icons}#bell`}></use>
        </svg>
      </IconButton>
      <IconButton className="d-md-none" onClick={handleNewPost}>
        <svg width="12" height="12">
          <use href={`${icons}#plus`}></use>
        </svg>
      </IconButton>
      <AddNewPost
        open={newPost}
        handleClose={handleNewPostClose}
        setSuccess={setSuccess}
        success={success}
      />
      <Notifications
        anchorEl={notificationsAnchorEl}
        handleClose={() => setNotificationsAnchorEl(null)}
      />

      <Divider orientation="vertical" flexItem />

      <div className="d-flex align-items-center">
        <img
          src={image_url || placeholder}
          width="40"
          height="40"
          style={{
            borderRadius: "100px",
            marginLeft: "10px",
          }}
          alt=""
          onError={({ target }) => {
            target.src = placeholder;
          }}
        />
        <span className="mx-2">{resolvedName}</span>
        <IconButton
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <svg width="8" height="6">
            <use href={`${icons}#arrowdown`}></use>
          </svg>
        </IconButton>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "15rem",
              marginTop: "1rem",
              borderRadius: "1rem",
            },
          }}
        >
          <MenuItem>
            <img
              src={image_url || smallpholder}
              width="30"
              height="30"
              style={{
                borderRadius: "100px",
              }}
              alt=""
              onError={({ target }) => {
                target.src = smallpholder;
              }}
            />
            <span className="mx-2">{resolvedName}</span>
          </MenuItem>
          <Divider />
          <MenuItem>
            <Link className="w-100 px-2 py-2 medium-text" to="/profile">
              Profile
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              className="w-100 px-2 py-2 medium-text"
              to="/settings/notifications"
            >
              Notifications
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link className="w-100 px-2 py-2 medium-text" to="/settings/help">
              Help/Support
            </Link>
          </MenuItem>
          <MenuItem>
            <Link className="w-100 px-2 py-2 medium-text" to="/settings/about">
              About workbrook
            </Link>
          </MenuItem>
          
          <MenuItem>
            <Link
              className="w-100 px-2 py-2 medium-text"
              to="/settings/security"
            >
              Change My Password
            </Link>
          </MenuItem>
          <Divider />
          <MenuItem className="px-4 py-3 co-lightred medium-text">
            <Link to="#" onClick={logoutAction}>
              Logout of workbrook
            </Link>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ToolbarMenu;
