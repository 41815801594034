import httpRequest from '../../ajax';
import {
  GET_EDUCATIONS,
  CREATE_EDUCATION,
  UPDATE_EDUCATION,
  GET_EDUCATION,
  ERROR,
} from '../actionTypes';

/**
 * @description get all education action
 * @function getAllEducationAction
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getAllEducationAction = (dispatch) => {
  return httpRequest('get','/settings/profile/education')
    .then(({ data: { data }}) => {
      const action = { type: GET_EDUCATIONS, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description get single education action
 * @function getSingleEducationAction
 * @param {string} educationId - education id
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const getSingleEducationAction = (educationId, dispatch) => {
  return httpRequest('get',`/settings/profile/education/${educationId}`)
    .then(({ data: { data }}) => {
      const action = { type: GET_EDUCATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description update education action
 * @function updateEducationAction
 * @param {string} educationId - education id
 * @param {object} requestData - request data
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const updateEducationAction = (educationId, requestData, dispatch) => {
  return httpRequest('post',`/settings/profile/education/${educationId}/update`, requestData)
    .then(({ data: { data }}) => {
      getAllEducationAction(dispatch);
      const action = { type: UPDATE_EDUCATION, payload: data };
      dispatch(action);
      return action;
    })
    .catch(({ response }) => ({
      payload: response?.data?.message,
      type: ERROR,
    }));
};

/**
 * @description create education action
 * @function createEducationAction
 * @param {object} requestData - request data
 * @param {function} dispatch - dispatch function
 * @returns {object} - returns the action
 */
export const createEducationAction = (requestData, dispatch) => httpRequest('post','/settings/profile/education/create', requestData)
  .then(({ data: { data }}) => {
    const action = { type: CREATE_EDUCATION, payload: data };
    dispatch(action);
    return action;
  })
  .catch(({ response }) => ({
    payload: response?.data?.message,
    type: ERROR,
  }));

