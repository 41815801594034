import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';


import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bool, func } from "prop-types";
import { IconButton } from "@material-ui/core";
import icons from "../../assets/icons/icon-collection.svg";
import CustomButton from "../../components/CustomButton";
// import { Tabs, Tab } from "@material-ui/core";
import cogoToast from "cogo-toast";
import {
  createJobAction,
  getJobLevelsAction,
} from "../../redux/actions/jobActions";
import { getIndustryAction } from "../../redux/actions/industryActions";
import { getAllSkillsAction } from "../../redux/actions/skillsAction";
import JobDetails from "./JobDetails";
import AddDocuments from "./JobDocuments";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { DemoPointer } from "../../demo";
import { demoAction } from "../../redux/actions/demoActions";
import ReactTooltip from 'react-tooltip';
import { openRecruiterModal } from "../../redux/actions/demoActions";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
}));


const AddNewPost = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [industryOptions, setIndustryOptions] = useState({});
  const [section, setSection] = useState("details");
  const [levelOptions, setLevelOptions] = useState({});
  const [skillOptions, setSkillOptions] = useState({});
  const [isJobCreated, setIsJobCreated] = useState(false);
  const history = useHistory();
  const [newJob, setNewJob] = useState({
    campany_name: "",
    industry: "0",
    about_company: "",
    no_of_staff: "0",
    job_level: "0",
    role_title: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    location: "",
    additional_files: [],
    isToRefTimeLineOrToHireRecruter: 1,
    isCompanyRepresentative: "1",
    salary: "",
    duration: "",
    canRecruiterRequest: "0",
    country:0
  });
  const { industry, skill, job } = useSelector((store) => store);
  const { allSkills } = skill;
  const { industries } = industry;
  const { jobLevels, jobCreated } = job;

  useEffect(() => {
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
    ]);
  }, [dispatch]);

  useEffect(() => {
    const options = {};
    industries && industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
  }, [industries]);

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    setSkillOptions(allSkills);
    // setSkillOptions(options);
  }, [allSkills]);

  useEffect(() => {
    const options = {};
    jobLevels &&
      jobLevels.forEach((level) => (options[level.id] = level.title));
    setLevelOptions(options);
  }, [jobLevels]);

  const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  };


  const updateSelectedCountryID = (cid) => {
     setNewJob({ ...newJob, ...{ country: cid } });
  };

  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };
  const [linksObject, setLinksObject] = useState([]);
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };
  const [value, setValue] = useState(0);
  
  const handleSubmit = async (e) => {
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );
    
 
    e.preventDefault();
    // if (section === "documents") {
    //   setSection("details");
    // } else {
    await createJobAction(dispatch, {
      ...newJob,
      ...{
        job_level,
        isCompanyRepresentative,
        canRecruiterRequest: parseInt(newJob.canRecruiterRequest),
      },
    });
    setIsJobCreated(true);
    // }
  };

  const sendToRecruiter = async (e) => {
    e.preventDefault();
    openRecruiterModal(dispatch);

    /*
    e.preventDefault();
    if (
      !newJob.campany_name ||
      !newJob.industry ||
      !newJob.about_company ||
      !newJob.no_of_staff ||
      !newJob.job_level ||
      !newJob.role_title ||
      !newJob.job_type ||
      !newJob.reports_to ||
      !newJob.applicant_benefit ||
      !newJob.skills_required ||
      !newJob.location ||
      !newJob.salary ||
      !newJob.duration
    ) {
      return cogoToast.error("Please fill all fields");
    }
    const job_level = parseInt(newJob.job_level, 10);
    const isCompanyRepresentative = parseInt(
      newJob.isCompanyRepresentative,
      10
    );
    const canRecruiterRequest = parseInt(newJob.canRecruiterRequest, 10);
    const jobToSend = {
      ...newJob,
      ...{ job_level, isCompanyRepresentative, canRecruiterRequest },
    };

    history.push({
      pathname: "/recruiter/hire",
      state: { jobToSend },
    });
    closeDialog();


    */
  };

  const closeDialog = () => {
    setIsJobCreated(false);
    handleClose();
  };

  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  return (
    <div>
     <ReactTooltip/>

      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            minWidth: "50rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          }, 
        }}
      >
        <div className="d-flex justify-content-between align-items-centter">
          <DialogTitle id="form-dialog-title">
            {" "}
            {!jobCreated && <>Post a New Jobs</>}

        <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Add Documents" {...a11yProps(1)} />
         </Tabs>
        </AppBar>

          </DialogTitle>
          <div className="close-button">
            <IconButton onClick={handleClose}>
              <svg width="30" height="30" className="rounded-circle">
                <use href={`${icons}#close`}></use>
              </svg>
            </IconButton>
          </div>
        </div>
        {isJobCreated && jobCreated && (
          <DialogContent>
            <div className="d-flex flex-column justify-content-center align-items-center mx-auto my-5">
              <svg width="119" height="119" className="rounded-circle">
                <use href={`${icons}#checkmark`}></use>
              </svg>
              <p className="co-green mt-2 small-text">
                Job successfully Published
              </p>
            </div>
          </DialogContent>
        )}
        {!jobCreated && (
          <DialogContent>
            <form onSubmit={handleSubmit}>
            
            
     
      <SwipeableViews
        axis={'x-reverse'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <TabPanel value={value} index={0} >
      
              {/* <CustomButton
                      type="button"
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text={"Details"}
                      handleClick={(e) => {
                        e.preventDefault();
                        const state = {
                          ["Fill In Details"]: true,
                          ["Don't forget to add your files and links"]: false,
                        };
                        demoAction(dispatch, state);
                        setSection("details");
                      }}
                      additionalClass="mx-3 mt-2"
                    /> */}


              <JobDetails
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  updateSelectedCountryID={updateSelectedCountryID}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                />

        </TabPanel>


        <TabPanel value={value} index={1}>
        
                 {/* <CustomButton
                      type="submit"
                      backgroundColor="#41B883"
                      handleClick={(e) => {
                        e.preventDefault();
                        const state = {
                          ["Fill In Details"]: false,
                          ["Don't forget to add your files and links"]: true,
                        };
                        demoAction(dispatch, state);
                        setSection("documents");
                      }}
                      color="#fff"
                      text={"Documents"}
                      additionalClass="mx-3 mt-2"
                    /> */}


            <AddDocuments
                  job={newJob}
                  addDocLinkObject={(link) => addDocLinkObject(link)}
                  addDocLink={(link) => addDocLink(link)}
                  linksObject={linksObject}
                />

        </TabPanel>
       
      </SwipeableViews>

            {/*
              <div className="mb-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                     <CustomButton
                      type="button"
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text={"Details"}
                      handleClick={(e) => {
                        e.preventDefault();
                        const state = {
                          ["Fill In Details"]: true,
                          ["Don't forget to add your files and links"]: false,
                        };
                        demoAction(dispatch, state);
                        setSection("details");
                      }}
                      additionalClass="mx-3 mt-2"
                    />
                   
                    <CustomButton
                      type="submit"
                      backgroundColor="#41B883"
                      handleClick={(e) => {
                        e.preventDefault();
                        const state = {
                          ["Fill In Details"]: false,
                          ["Don't forget to add your files and links"]: true,
                        };
                        demoAction(dispatch, state);
                        setSection("documents");
                      }}
                      color="#fff"
                      text={"Documents"}
                      additionalClass="mx-3 mt-2"
                    />
                 </div>
              </div>
              */}

              {/* <TabPanel value={section} index={"details"}> */}
              <div
                style={{ display: section === "details" ? "block" : "none" }}
              >
               {/* <JobDetails
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                /> */}
              </div>
              {/* </TabPanel> */}

              {/* <TabPanel value={section} index={"documents"}> */}
              <div
                style={{ display: section === "documents" ? "block" : "none" }}
              >
               {/* <AddDocuments
                  job={newJob}
                  addDocLinkObject={(link) => addDocLinkObject(link)}
                  addDocLink={(link) => addDocLink(link)}
                  linksObject={linksObject}
                /> */}
              </div>
              {/* </TabPanel> */}
              {/* {section === "details" && (
                <JobDetails
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                />
              )} */}
              {/* {section === "documents" && (
                <AddDocuments
                  job={newJob}
                  addDocLinkObject={(link) => addDocLinkObject(link)}
                  addDocLink={(link) => addDocLink(link)}
                  linksObject={linksObject}
                />
              )} */}
            </form>
          </DialogContent>
        )}
        {!jobCreated && (
          <DialogActions>
            
            <CustomButton
              type="button"
              backgroundColor="#3A6ED4"

              color="#fff"
              text={"Send to Recruiter"}
              handleClick={sendToRecruiter}
              additionalClass="mx-3 mt-2"
           
            />
             
         
              <CustomButton
                type="submit"
            
                backgroundColor="#41B883"
                handleClick={(e) => {
                  e.preventDefault();
                  const state = {
                    ["Post a Job"]: false,
                    ["Click on Post a Job"]: false,
                    ["Don't forget to add your files and links"]: false,
                    ["Post to referral timeline"]: true,
                    ["Fill In Details"]: false,
                  };
                  demoAction(dispatch, state);
                  handleSubmit(e);
                }}
                color="#fff"
                text={"Publish to Referral"}
                additionalClass="mx-3 mt-2"
              />
        
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

AddNewPost.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
};

export default AddNewPost;

{/*
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tab-panel"
      hidden={value !== index}
      id={`simple-tab-panel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
*/}