/* eslint-disable react/prop-types */
import React, { useState,useEffect } from "react";
// import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  InputLabel,
  FormControlLabel
} from "@material-ui/core";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTextArea from "../../../../components/CustomTextArea";
import { func, object } from "prop-types";
 
import icons from "../../../../assets/icons/icon-collection.svg";
// import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SearchLocationInput from "../../../../components/LocationComponent";
import { FormControl, IconButton, MenuItem, Select } from "@material-ui/core";

const JobDetails = ({
  newJob,
  handleChange,
  industryOptions,
  levelOptions,
  skillOptions,
  subsidiaryOptions,
  departmentOptions,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const dispatch = useDispatch();
 
  // const { userData } = useSelector(({ auth }) => auth);
  // const [location, setLocation] = useState({
  //   location: userData.location || "",
  //   coordinate: "",
  // });

  // console.log("subs", subsidiaryOptions);
 

  const addSkill = ({ target: { value } }) => {
    const skills = value;
    setSelectedSkills(skills);
    handleChange({ target: { value: skills, name: "skills_required" } });
  };
 

 


  const handleLocationChange = (place) => {
    // console.log("hello...");
    handleChange({ target: { value: place, name: "location" } });
    // setLocation({ coordinate: "", location: place });
  };
  const displaySkills = () => {
    return selectedSkills.map((skill, index) => (
      <span
        className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 outlined"
        key={index.toString()}
      >
        {skill}
        <IconButton onClick={() => removeSkill(skill)}>
          <svg width="10" height="8">
            <use href={`${icons}#close-blue`}></use>
          </svg>
        </IconButton>
      </span>
    ));
  };
  const removeSkill = (skillToRemove) => {
    const skillArray = [...selectedSkills];
    const newSkillArray = skillArray.filter((skill) => skill !== skillToRemove);
    setSelectedSkills(newSkillArray);
    handleChange({ target: { value: newSkillArray, name: "skills_required" } });
  };

  return (
    <>
      {/* <SearchLocationInput handleLocationChange={handleLocationChange} /> */}
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select Subsidiary", ...subsidiaryOptions }}
        id="subsidiary_id"
        name="subsidiary_id"
        label="Subsidiaries"
        currentValue={newJob.subsidiary_id}
      />
      <CustomInput
        type="text"
        onChange={handleChange}
        name="role_title"
        value={newJob.role_title}
        label="Role title"
        id="role"
      />
      <SearchLocationInput
        inputStyle="TextField"
        handleLocationChange={handleLocationChange}
      />


   
 


      {/* <CustomInput
        type="text"
        onChange={handleChange}
        name="location"
        value={newJob.location}
        label="Location"
        id="location"
      /> */}
      <CustomInput
        type="text"
        onChange={handleChange}
        name="reports_to"
        value={newJob.reports_to}
        label="Reports To"
        id="report"
      />
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select Level", ...levelOptions }}
        id="level"
        name="job_level"
        label="Job Level"
        currentValue={newJob.job_level}
      />
      <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Type",
          "part-time": "Part-time",
          "full-time": "Full-time",
          contract: "Contract",
          internship: "Internship",
          volunteer: "Volunteer",
        }}
        id="job_type"
        name="job_type"
        label="Type of Employment"
        currentValue={newJob.job_type}
      />
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select an Industry", ...industryOptions }}
        id="industry"
        name="industry"
        label="Industry"
        currentValue={newJob.industry}
      />
      <CustomSelect
        handleChange={handleChange}
        id="department"
        name="department"
        label="Department"
        // value={state.department}
        options={departmentOptions}
        currentValue={newJob.department}
      />
      <CustomTextArea
        onChange={handleChange}
        name="about_role"
        value={newJob.about_role}
        label="About Company/Role"
        id="about_role"
      />
      {/* <CustomSelect
        name="skills_required"
        handleChange={handleChange}
        options={skillOptions}
        id="skills"
        label="Skills"
        currentValue={newJob.skills_required}
        multiple
      /> */}
      
      <FormControl variant="standard" className="custom-input w-25 w-100 mt-3">
        <label className="d-block mb-0">Skills</label>
        <Select
          id="skills"
          label="skills"
          onChange={addSkill}
          value={selectedSkills}
          multiple={true}
        >
          <MenuItem value=" ">
            <em>Add Skill (ex: Interior Design)</em>
          </MenuItem>
          {skillOptions &&
            skillOptions
              .filter(
                ({ title }) => !selectedSkills.find((val) => val === title)
              )
              .map(({ title, id }) => (
                <MenuItem key={id} value={title}>
                  {title}
                </MenuItem>
              ))}
        </Select>
        <div className="skills-count mt-3 w-100 d-flex align-items-center">
          <span className="blob rounded px-2 py-1 bg-green co-white">
            <b>{selectedSkills.length}</b>
          </span>
          <span className="ml-2 co-green medium-text">Skills count</span>
        </div>
        {selectedSkills[0] && (
          <div className="mt-5 w-100 d-flex flex-wrap">{displaySkills()}</div>
        )}
      </FormControl>

      {/* <RadioGroup
        className="mt-2"
        name="isCompanyRepresentative"
        value={newJob.isCompanyRepresentative}
        onChange={handleChange}
      >
        <label>Are you a company representative?</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup> */}
    </>
  );
};

JobDetails.propTypes = {
  newJob: object.isRequired,
  setSelectedSkills: func.isRequired,
  handleChange: func.isRequired,
  industryOptions: object.isRequired,
  levelOptions: object.isRequired,
  skillOptions: object.isRequired,
};

export default JobDetails;
