import React, { useEffect,useRef,useState } from "react";
import BackButton from "../../../components/BackButton";
import Card from "../../../components/Card";
import Layout from "../../../layout";
import Cup from "../../../assets/icons/cup.svg";
import CustomButton from "../../../components/CustomButton";
import CustomTextArea from "../../../components/CustomTextArea";
import CustomInput from "../../../components/CustomInput";
import { useHistory, useLocation } from "react-router-dom";
import LeaderList from "./LeaderList";
import cogoToast from "cogo-toast";
import { useDispatch, useSelector } from "react-redux";
import { SectionLoader } from "../../../components/PageLoader";
import { Box, Grid, Paper, Typography, CardHeader, CardContent } from '@material-ui/core';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
  
import pictureLogo from './feeds.png';
import { uploadFile } from "../../../utils/helpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Points from "./Points";
import './feeds.css';
import {
    createFeedAction,
    getFeedsAction,
    createCommentPostAction
  } from "../../../redux/actions/feedAction";

const League = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const myFormRef = useRef(0);
  const uploadInputRef = useRef(0);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showComments,setShowComment ] = useState(false);
  const [commentValue,setCommentValue ] = useState("");

   
  const {get_feed_result} = useSelector((store) => store);

  const [stateRecognition, setStateRecognition] = useState({
    content:"",
    img_url:""
   });

   const [stateComment, setCommentState] = useState({
    feed_id:"",
    content:""
   });


   useEffect(() => 
   {
    
    setLoading(true);   
    Promise.resolve(getFeedsAction(dispatch, 1)).finally(() =>
    {
     setTimeout(()=>{
        setLoading(false);
     }, 500)
           
      
     }
    );
    }, [dispatch, 1]);


   const handleChangeUpload = ({ target: { files } }) => {
    if (files && files[0]) {
        setIsUploading(true);
        
        Promise.resolve(
          uploadFile(
            files[0],
            "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
          )
        )
        .then(({ data, success }) => {
            
          if (success) {
            //addDocLink(data);
            setStateRecognition({ ...stateRecognition, img_url: data });
            // addDocLinkObject({ link: data, name: files[0].name });
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  };
  
    const handleChange = ({ target: { id, value, checked } }) => {
      setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
    };

    const handleChangeComment = ({ target: { id, value, checked } }) => {
      setCommentState((prevState) => ({ ...prevState, [id]: value }));
    };
     
  
    const handleRefClick = () => {
      uploadInputRef.current.click();
    };
  

     

  const handleSubmitComment = async (e) => {
     
      //setLoading(true)
      e.preventDefault();
    
    const promise =  createCommentPostAction(dispatch, {
        ...stateComment,
      });
      Promise.resolve(promise).finally(() => {
          //  setLoading(false)
            setTimeout( ()=>{
             window.location.reload(false);
            },3000)
         // myFormRef.reset();

          
      });

      
     };

  const handleSubmit = async (e) => {
     
    setLoading(true)
    e.preventDefault();
    

  const promise =  createFeedAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
          setTimeout( ()=>{
            window.location.reload(false);
          },500)
        
    });
   };


  const displayData = () => {
    if (pathname.includes("list")) {
      return <LeaderList />;
    } else if (pathname.includes("point")) {
      return <Points />;
    } else {
      return (
<div className="w-100 d-flex pt-5 flex-column">
 
 <div className="col-md-10 gedf-main" style={{marginLeft:'10%'}}>
 
  <form onSubmit={handleSubmit}> 
  <div className="card gedf-card">
    
    <div className="card-body">
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
                <div className="form-group">
                    <label className="sr-only" for="message">post</label>
                    
                 <CustomTextArea
                  placeholder="Post a content..."
                  onChange={handleChange}
                  value={stateRecognition.content}
                  name="content"
                  id="content"
                 />

                </div>

            </div>
           
                  <div>
                      <input
                        ref={uploadInputRef}
                        type="file"
                        onChange={handleChangeUpload}
                        style={{ display: "none" }}
                      />           
                    </div>
         
         </div>



        <div className="btn-toolbar justify-content-between">

            <div className="btn-group">
            <button className="btn mgtr btn-default" type="button" onClick={handleRefClick}>
            <Grid container spacing={2}>
            <Grid item xs={6}>
            <img src={pictureLogo} className="post-div-logo" /> 
            </Grid> 

            <Grid item xs={6}>
            <img src={stateRecognition.img_url} className="post-div-logo-result" />  
            </Grid>

            </Grid>

            </button>
             </div>
             <button type="submit" className="btn  

             btn-primary blue-button pull-right">Post</button>            

               
 
           {/* <div className="btn-group">
                <button id="btnGroupDrop1" type="button" className="btn btn-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i className="fa fa-globe"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                    <a className="dropdown-item" href="#"><i className="fa fa-globe"></i> Public</a>
                    <a className="dropdown-item" href="#"><i className="fa fa-users"></i> Friends</a>
                    <a className="dropdown-item" href="#"><i className="fa fa-user"></i> Just me</a>
                </div> 
            </div>
            */}
        </div>
    </div>
</div>

   </form>
   {loading && <SectionLoader />}
 
 {
     get_feed_result
     &&
     get_feed_result.get_feed_result 
     &&
     get_feed_result.get_feed_result.map( (item, index)=>{
         return(

            <div className="card gedf-card" key={index}>
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mr-2">
                            <img className="rounded-circle" width="45" src={item.user[0].image_url} alt="" />
                        </div>
                        <div className="ml-2">
                            <div className="h5 m-0">@{item.user[0].last_name}{item.user[0].first_name}</div>
                            <div className="h7 text-muted">{item.user[0].title}</div>
                        </div>
                    </div>
                    <div>
                        <div className="dropdown">
                            <button className="btn btn-link dropdown-toggle" type="button" id="gedf-drop1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-h"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="gedf-drop1">
                                <div className="h6 dropdown-header">Configuration</div>
                                <a className="dropdown-item" href="#">Save</a>
                                <a className="dropdown-item" href="#">Hide</a>
                                <a className="dropdown-item" href="#">Report</a>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div className="card-body">
                <div className="text-muted h7 mb-2"> <i className="fa fa-clock-o"></i>{item.created_at}</div>
               
                <p className="card-text">
                 {item.content}
                </p>
                <img src={item.img_url}
                style={{width:"30%"}}
                />
            </div>
            <div className="card-footer">
            
                     <Accordion style={{backgroundColor:'#fff'}}>


                        <AccordionItem>
                        <AccordionItemHeading>
                        <AccordionItemButton>
                         Comments                
                         </AccordionItemButton>
                         </AccordionItemHeading>
                        <AccordionItemPanel>
                        
                        <form ref={myFormRef} onSubmit={handleSubmitComment}> 
                        <CustomInput
                            name="comment"
                            label=""
                            onChange={({ target: { id, value, checked } })=>{
                              setCommentState({ ...stateComment, feed_id: item.id, content: value});
                            
                            }}
                            id="comment"
                            
                            placeholder="Enter Comment..."
                            />

                        <button type="submit" 
                        className="btn btn-primary  btn-sm pull-right">Comment</button>    
                        </form>

                     <div style={{marginTop:'2.5%'}}>
                       {
                         item.comments.map( (item2, index)=>{
                           return(
                            <div className="comment mt-4x text-justifyx float-left"> 
                            <img src="https://res.cloudinary.com/workbrook-hash/image/upload/v1587164870/App/user-circle-02_jbpaez.png" alt="" className="rounded-circle" width="40" height="40"/>
                                <h4 className="black font-size-small">@{item2.commented_by_user[0].first_name}{item2.commented_by_user[0].last_name}</h4> <span>- {item2.commented_by_user[0].title}</span> <br/>
                                <p style={{color:'#000', width:"450px"}}>{item2.content}</p>
                             </div>
                           )
                         })
                  

                       }

                  

                    
                    </div>
                            
                        </AccordionItemPanel>

                    
                            
                 
                   

                        </AccordionItem>


                        </Accordion>

            </div>
        </div>

         )
     })
 

 }
 
{/*
 <div className="card gedf-card">
    <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                    <img className="rounded-circle" width="45" src="https://picsum.photos/50/50" alt="" />
                </div>
                <div className="ml-2">
                    <div className="h5 m-0">@LeeCross</div>
                    <div className="h7 text-muted">Miracles Lee Cross</div>
                </div>
            </div>
            <div>
                <div className="dropdown">
                    <button className="btn btn-link dropdown-toggle" type="button" id="gedf-drop1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fa fa-ellipsis-h"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="gedf-drop1">
                        <div className="h6 dropdown-header">Configuration</div>
                        <a className="dropdown-item" href="#">Save</a>
                        <a className="dropdown-item" href="#">Hide</a>
                        <a className="dropdown-item" href="#">Report</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div className="card-body">
        <div className="text-muted h7 mb-2"> <i className="fa fa-clock-o"></i> 10 min ago</div>
        <a className="card-link" href="#">
            <h5 className="card-title"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit consectetur
                deserunt illo esse distinctio.</h5>
        </a>

        <p className="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam omnis nihil, aliquam est, voluptates officiis iure soluta
            alias vel odit, placeat reiciendis ut libero! Quas aliquid natus cumque quae repellendus. Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Ipsa, excepturi. Doloremque, reprehenderit!
            Quos in maiores, soluta doloremque molestiae reiciendis libero expedita assumenda fuga quae.
            Consectetur id molestias itaque facere? Hic!
        </p>
        <div>
        
        </div>
    </div>
    <div className="card-footer">
        <a href="#" className="card-link"><i className="fa fa-gittip"></i> Like</a>
         <a href="#" className="card-link"><i className="fa fa-mail-forward"></i> Comment</a>
    </div>
</div>
 

 <div className="card gedf-card">
    <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
                <div className="mr-2">
                    <img className="rounded-circle" width="45" src="https://picsum.photos/50/50" alt=""/>
                </div>
                <div className="ml-2">
                    <div className="h5 m-0">@LeeCross</div>
                    <div className="h7 text-muted">Miracles Lee Cross</div>
                </div>
            </div>
            <div>
                <div className="dropdown">
                    <button className="btn btn-link dropdown-toggle" type="button" id="gedf-drop1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fa fa-ellipsis-h"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="gedf-drop1">
                        <div className="h6 dropdown-header">Configuration</div>
                        <a className="dropdown-item" href="#">Save</a>
                        <a className="dropdown-item" href="#">Hide</a>
                        <a className="dropdown-item" href="#">Report</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div className="card-body">
        <div className="text-muted h7 mb-2"> <i className="fa fa-clock-o"></i> Hace 40 min</div>
        <a className="card-link" href="#">
            <h5 className="card-title">Totam non adipisci hic! Possimus ducimus amet, dolores illo ipsum quos
                cum.</h5>
        </a>

        <p className="card-text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam sunt fugit reprehenderit consectetur exercitationem odio,
            quam nobis? Officiis, similique, harum voluptate, facilis voluptas pariatur dolorum tempora sapiente
            eius maxime quaerat.
            <a href="https://mega.nz/#!1J01nRIb!lMZ4B_DR2UWi9SRQK5TTzU1PmQpDtbZkMZjAIbv97hU" target="_blank">https://mega.nz/#!1J01nRIb!lMZ4B_DR2UWi9SRQK5TTzU1PmQpDtbZkMZjAIbv97hU</a>
        </p>
    </div>
    <div className="card-footer">
        <a href="#" className="card-link"><i className="fa fa-gittip"></i> Like</a>
         <a href="#" className="card-link"><i className="fa fa-mail-forward"></i> Comment</a>
    </div>
</div>
 
*/}

</div>

        
         {/* 
          <img className="d-block mx-auto mt-5" src={Cup} alt="cup" />
          <h5 className="text-center my-5">
            How you earn points in the WB League
          </h5>
          <ul className="d-flex co-gray w-100 flex-column align-items-center justify-content-center">
            <li className="my-1">
              Earn points by completing tasks on the app.
            </li>
            <li className="my-1">Complete your profile to 100%.</li>
            <li className="my-1">Make a successful referral.</li>
            <li className="my-1">Invite a friend to use workbrook.</li>
            <li className="my-1">Convert your earned points to real money.</li>  
          </ul>
          <h5 className="co-primary text-center my-5">
            Complete Your Profile (5pts)
          </h5>

          <div className="w-75 my-3 mx-auto">
            <CustomButton
              handleClick={() => history.push("/league/points")}
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Take me to WB Points"
              additionalClass="my-3"
            />
          </div>  
        */}

        </div> 
      );
    }
  };

  return (
    <Layout>
      <BackButton />
 
        <div className="mx-4x pb-4z">{displayData()}</div>
   
    </Layout>
  );
};

export default League;
