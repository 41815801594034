import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  // useHistory
} from "react-router-dom";
import Card from "../../components/Card";
import blueWBlogo from "../../assets/icons/blueWBlogo.svg";
import Illustration from "../../assets/images/workbrook-league.png";
import CustomBadge from "../../components/CustomBadge";
import CustomButton from "../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getLeagueAction } from "../../redux/actions/leagueAction";
import { getMyRecruiterData } from "../../redux/actions/recruiterAction";
import { openRecruiterModal } from "../../redux/actions/demoActions";

const LeaderBoard = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [canPost, setCanPost] = useState(true);
  const { pathname } = useLocation();
  const { league } = useSelector(({ league }) => league);

  const {
    userData: { account_type, isRecruiter },
  } = useSelector((store) => store.auth);

  const { myRecruiterData } = useSelector(({ recruiter }) => recruiter);

  useEffect(() => {
    if (isRecruiter === 0 && account_type === "business") {
      setCanPost(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getLeagueAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getMyRecruiterData(dispatch);
  }, [dispatch]);

  return (
    <Card>
      {!pathname.includes("league/list") && (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <span className="d-flex align-items-center">
              <img src={blueWBlogo} alt="logo" />
              <h6 className="mb-0 ml-2">WB LEAGUE</h6>
            </span>
            <Link to="/league/list">See More</Link>
          </div>
          {league ? (
            league && (
              <div>
                {league.map((person, index) => {
                  return index < 5 ? (
                    <div
                      key={person.id}
                      className="d-flex justify-content-between align-items-center mt-3"
                    >
                      <span className="d-flex align-items-center">
                        <img
                          className="rounded-circle"
                          width="50"
                          src={person.image_url}
                          alt="avatar"
                        />
                        <span className="d-flex flex-column ml-3 small-text">
                          <h6 className="mb-0">
                            {person.first_name} {person.last_name}
                          </h6>
                          <span className="co-gray">@{person.username}</span>
                        </span>
                      </span>
                      <CustomBadge figure={index + 1 + ""} />
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>
            )
          ) : (
            <div className="mt-4 ml-2">
              Complete Your profile to view League List
            </div>
          )}
        </div>
      )}

       {/* <div className="d-flex align-items-center mt-5 mb-3 justify-content-between">
        <span className="d-flex align-items-center">
          <img src={blueWBlogo} alt="logo" />
          <h6 className="mb-0 ml-2">INFO CENTER</h6>
        </span>
          </div>*/}

      {canPost && (
        <Card
          additionalClass="mb-5 small-border-radius"
          backgroundColor="#3A6ED4"
          color="#fff"
        >
          <img
            className="py-3"
            src={Illustration}
            style={{ width: "100px" }}
            alt="illustration"
          />
          <h6 className="my-3 ">
            Are you a recruiter? The workbrook recruiter platform is just for
            you!
          </h6>

          <div className="joyride-become-recruiter">
          {myRecruiterData ? (
            <CustomButton
              backgroundColor="#fff"
              color="#3A6ED4"
              text="View Recruiter Profile"
              additionalClass="my-3"
              handleClick={() => {
                // history.push(`/recruiter/profile`);
                openRecruiterModal(dispatch);
              }}
            />
          ) : (
            <CustomButton
              backgroundColor="#fff"
              color="#3A6ED4"
              text="Become A Recruiters"
              additionalClass="my-3"
              handleClick={() => {
                // history.push("/recruiter");
                openRecruiterModal(dispatch);
              }}
            />
          )}
          </div>
        </Card>
      )}
    </Card>
  );
};

export default LeaderBoard;
