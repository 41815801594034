import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyJobsAction,editJobAction } from "../../../redux/actions/jobActions";
import { SectionLoader } from "../../../components/PageLoader";
import icons from "../../../assets/icons/icon-collection.svg";
import EmptyState from "../../../components/EmptyState";
import { Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
    createBusinessJobAction,
    getSubsidiaries,
  } from "../../../redux/actions/businessActions";
 

  import {
    FormControlLabel,
    Radio,
    RadioGroup,
    InputLabel
  } from "@material-ui/core";
  import { getCountryAction } from "../../../redux/actions/countryActions";
  import { FormControl, Select } from "@material-ui/core";

   
import CustomInput from "../../../components/CustomInput";
import SearchLocationInput from "../../../components/LocationComponent";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextArea from "../../../components/CustomTextArea";

import LinkButton from "../../../components/LinkButton";
import Card from "../../../components/Card";
import CustomButton from "../../../components/CustomButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PropTypes, func, object, string } from "prop-types";

import { Box } from "@material-ui/core";

import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import Layout from "../../../layout";
import JobDetailsEdit from "../../Business/BusinessNewPost/JobDetails/edit";
import AddDocumentsEdit from "../../Business/BusinessNewPost/JobDocuments/edit";
 

import { getJobLevelsAction, getJob } from "../../../redux/actions/jobActions";
import { getIndustryAction } from "../../../redux/actions/industryActions";
import { getAllSkillsAction } from "../../../redux/actions/skillsAction";
//import AddDocuments from "./JobDocuments";
import cogoToast from "cogo-toast";
import { useParams } from "react-router-dom";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }



const EditJob = () => {
  const dispatch = useDispatch(); 
  const [page] = useState(1);
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("details");
  const [jobCreated, setJobCreated] = useState(false);
  const [industryOptions, setIndustryOptions] = useState({});
  const [skillOptions, setSkillOptions] = useState({});
  const [subsidiaryOptions, setSubsidiaryOptions] = useState({});
  const [linksObject, setLinksObject] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedCountry,setSelectedCountry] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const params = useParams();

  const [state, setState] = useState({
    country_id:selectedCountry || 0
  });
  const [levelOptions, setLevelOptions] = useState({});
  const [newJob, setNewJob] = useState({
    company_id: "0",
    campany_name:"",
    subsidiary_id: "0",
    location: "",
    industry: "0",
    department: "",
    job_level: "0",
    role_title: "",
    about_company: "",
    job_type: "0",
    reports_to: "",
    applicant_benefit: "salary",
    skills_required: [],
    additional_files: [],
  });
  const { industry, skill, job, business } = useSelector((store) => store);
  const { allSkills } = skill;
  const { industries } = industry;
  const { editjob } = job; 

  useEffect(() => {
    const options = {};
    allSkills.forEach((skill) => (options[skill.title] = skill.title));
    // setSkillOptions(options);
    setSkillOptions(allSkills);
  }, [allSkills]);

  useEffect(() => {
    let theJobIdParam =  params.id;
    Promise.all([
      getIndustryAction(dispatch),
      getJobLevelsAction(dispatch),
      getAllSkillsAction(dispatch),
      getJob(dispatch, theJobIdParam ),
      getSubsidiaries(dispatch, company_id),
    ]);
    
 
  
   
  }, [dispatch]);


  const handleUpdateSubmit = async (e) => {
        
    setLoading(true)
    e.preventDefault();
     
   await editJobAction(dispatch, {
      ...newJob,
    });
       setLoading(false)
       setTimeout(()=>{
      //  window.location.reload(false);
       },5000);
  };




  const {
    userData: { account_type, company },
  } = useSelector((store) => store.auth);

  const company_id = company ? company.id : "";
  const [, setLoadingMore] = useState(false); 
  const [copied, setCopied] = useState(false);
  const addDocLinkObject = (link) => {
    const links = [...linksObject];
    links.push(link);
    setLinksObject(links);
  };
  useEffect(() => {
    if (copied) {
      cogoToast.success("Link copied");
      setCopied(false);
    }
  }, [copied]);
 

  const { myJobs } = useSelector(({ job }) => job);
  const jobs = myJobs;

  useEffect(() => {
    page === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getMyJobsAction(dispatch, page)).finally(() =>
      page === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, page]);

  const { country,profile } = useSelector(
    (store) => store
  );
  const { countries } = country;


  useEffect(() => {
    const options = {};
    industries &&  industries.forEach(
      (industry) => (options[industry.title] = industry.title)
    );
    setIndustryOptions(options);
    if (account_type === "business") {
      setNewJob({
        ...newJob,
        industry: industries[company.industry]?.title || "",
      });
    }
  }, [industries]);
 
 /* const handleChange = ({ target: { value, name } }) => {
    const changedObject = {};
    changedObject[name] = value;
    setNewJob({ ...newJob, ...changedObject });
  };

  */

//  const handleChange = ({ target: { id, value, checked } }) => {
 //   setNewJob((prevState) => ({ ...prevState, [id]: value }));
 // };

 const handleChange = ({ target: { id, value, checked } }) => {
  setNewJob((prevState) => ({ ...prevState, [id]: value }));
};


  const handleChangeCountry = ({ target: { value } }) => {
    const countryID = value;
   setSelectedCountry(countryID);
   setState({ ...state, country_id: countryID });
    };


  const addSkill = ({ target: { value } }) => {
  const skills = value;
      setSelectedSkills(skills);
      handleChange({ target: { value: skills, name: "skills_required" } });
    };

    const displaySkills = () => {
      return selectedSkills.map((skill, index) => (
        <span
          className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 outlined"
          key={index.toString()}
        >
          {skill}
          <IconButton onClick={() => removeSkill(skill)}>
            <svg width="10" height="8">
              <use href={`${icons}#close-blue`}></use>
            </svg>
          </IconButton>
        </span>
      ));
    };


  const addDocLink = (link) => {
    const links = [...newJob.additional_files];
    links.push(link);
    setNewJob({ ...newJob, ...{ additional_files: links } });
  };

  const removeSkill = (skillToRemove) => {
    const skillArray = [...selectedSkills];
    const newSkillArray = skillArray.filter((skill) => skill !== skillToRemove);
    setSelectedSkills(newSkillArray);
   // handleChange({ target: { value: newSkillArray, name: "skills_required" } });
  };

  /*
  const setSelectedSkills = (skills) => {
    setNewJob({ ...newJob, ...{ skills_required: skills } });
  }; */
  const handleSubmit = (e) => {
    const job_level = parseInt(newJob.job_level, 10);
    setLoading(true);

    e.preventDefault();
    // console.log("newJob", newJob);
    if (section === "documents") {
      setSection("details");
    }
    createBusinessJobAction(
      (action) => {
        setJobCreated(true);
        dispatch(action);
      },
      {
        ...newJob,
        ...{ job_level, company_id: parseInt(company_id, 10) },
      }
    );

    setLoading(false);
  };

   
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };


  
  const departmentOptions = {
    none: "None",
    engineering: "Engineering",
    customer_service: "Customer Service",
    information_technology: "Information and Technology",
    human_resources: "Human Resources",
    marketing: "Marketing",
    operations: "Operations",
    finance: "Finance",
    public_relations: "Public Relations",
    general_management: "General Management",
  };

  return (
    <Layout>
      <div className="" style={{
          backgroundColor:'#fff',
          padding:20
      }}>

           
         { editjob ?

            <form onSubmit={handleUpdateSubmit}>
            <h2>Edit Jobs</h2>
              

          <CustomInput
            type="text"
            onChange={handleChange}
            name="campany_name"
            value={newJob.campany_name|| editjob.campany_name}
            placeholder=""
            label="Name Of Company"
            id="campany_name"
          />

        <FormControl variant="outlined" className="w-100 mb-4">
            <SearchLocationInput
              inputStyle="TextField"
              value={newJob.location ||  editjob.location}
              handleLocationChange={(e) =>
                handleChange({
                  target: { value: e, name: "location" },
                })
              }
            />
          </FormControl>

        
  <InputLabel name="country-label">Select Country</InputLabel> 
     <Select
       labelId="country_id"
       name="country"
       label="country"
       value={newJob.country || editjob.country}
       onChange={handleChangeCountry}
       className="fullwidth-select"
     >
       <MenuItem value="">Select a Country</MenuItem>
       {countries.map(({ name, id }) => (
         <MenuItem key={id} value={id}>
           {name}
         </MenuItem>
       ))}
     </Select>

      <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Staff Strength",
          "Sole Employee": "Sole Employee",
          "2-20": "2-20",
          "21-50": "21-50",
          "51-200": "51-200",
          "201-500": "201-500",
          "501-1000": "501-1000",
          "1,001-10,000": "1,001-10,000",
          "10,001-50,000": "10,001-50,000",
          "50,001+": "50,001+",
        }}
        id="no_of_staff"
        name="no_of_staff"
        label="Staff Strength"
        currentValue={newJob.no_of_staff ||  editjob.no_of_staff}
      />
      <CustomInput
        type="text"
        onChange={handleChange}
        name="role_title"
        value={newJob.role_title ||  editjob.role_title}
        label="Role title"
        id="role_title"
      />
      <CustomInput
        type="text"
        onChange={handleChange}
        name="reports_to"
        value={newJob.reports_to || editjob.reports_to}
        label="Reports To"
        id="reports_to"
      />
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select Level", ...levelOptions }}
        id="job_level"
        name="job_level"
        label="Job Level"
        currentValue={ newJob.job_level || editjob.job_level}
      />
      <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Type",
          "part-time": "Part-time",
          "full-time": "Full-time",
          contract: "Contract",
          internship: "Internship",
          volunteer: "Volunteer",
        }}
        id="job_type"
        name="job_type"
        label="Type of Employment"
        currentValue={newJob.job_type ||  editjob.job_type}
      />
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select an Industry", ...industryOptions }}
        id="industry"
        name="industry"
        label="Industry"
        currentValue={newJob.industry ||  editjob.industry}
      />
      <CustomTextArea
        onChange={handleChange}
        name="about_company"
        value={newJob.about_company ||  editjob.about_company}
        label="About Company/Role"
        id="about_company"
      />
      <SalaryRow
        handleChange={handleChange}
        salary={newJob.salary ||  editjob.salary}
        duration={newJob.duration ||   editjob.duration}
      />
      {/* <CustomSelect
        name="skills_required"
        handleChange={handleChange}
        options={skillOptions}
        id="skills"
        label="Skills"
        currentValue={newJob.skills_required}
        multiple
      /> */}
      
      <FormControl variant="standard" className="custom-input w-25 w-100 mt-3">
        <label className="d-block mb-0">Skills</label>
        <Select
          id="skills"
          label="skills"
          onChange={addSkill}
          value={selectedSkills}
          multiple={true}
        >
          <MenuItem value=" ">
            <em>Add Skill (ex: Interior Design)</em>
          </MenuItem>
          {skillOptions &&
            skillOptions.filter &&
            skillOptions
              .filter(
                ({ title }) => !selectedSkills.find((val) => val === title)
              )
              .map(({ title, id }) => (
                <MenuItem key={id} value={title}>
                  {title}
                </MenuItem>
              ))}
        </Select>
        <div className="skills-count mt-3 w-100 d-flex align-items-center">
          <span className="blob rounded px-2 py-1 bg-green co-white">
            <b>{selectedSkills.length}</b>
          </span>
          <span className="ml-2 co-green medium-text">Skills count</span>
        </div>
        {selectedSkills[0] && (
          <div className="mt-5 w-100 d-flex flex-wrap">{displaySkills()}</div>
        )}
      </FormControl>
      <RadioGroup
        className="mt-2"
        name="isCompanyRepresentative"
        value={editjob.isCompanyRepresentative}
        onChange={handleChange}
      >
        <label>Are you a company representative?</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>

      <RadioGroup
        className="mt-2"
        name="canRecruiterRequest"
        value={editjob.canRecruiterRequest}
        onChange={handleChange}
      >
        <label>Allow recruiter reach out to you</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>
   







                {/*
              <JobDetailsEdit
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                  subsidiaryOptions={subsidiaryOptions}
                  departmentOptions={departmentOptions}
                />
       
            <AddDocumentsEdit
                  newJob={newJob}
                  setSelectedSkills={setSelectedSkills}
                  handleChange={handleChange}
                  industryOptions={industryOptions}
                  levelOptions={levelOptions}
                  skillOptions={skillOptions}
                  subsidiaryOptions={subsidiaryOptions}
                  departmentOptions={departmentOptions}
                />
                */}

            <CustomButton
              type="submit"
              backgroundColor="#41B883"
              handleClick={handleUpdateSubmit}
              color="#fff"
              loading={false}
              text={"Save Update"}
              additionalClass="mx-3 mt-2"
            />
 

      
      </form>
          :
         <div>
            { <SectionLoader />}
         </div>
        }
        
         
          

      </div>
    </Layout>
  );
};

export default EditJob;



const SalaryRow = ({ handleChange, duration }) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleBlur = (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "text";
    const stripped = text.replaceAll(",", "");
    const formatted = numberWithCommas(stripped);
    document.querySelector("#report-salary").value = formatted;
    handleChange({
      target: {
        value: text,
        name: "salary",
      },
    });
  };
  const handleFocus = async (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "number";
    const stripped = text.replaceAll(",", "");
    if (!isNaN(stripped))
      document.querySelector("#report-salary").value = stripped;

    handleChange({
      target: {
        value: stripped,
        name: "salary",
      },
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <div style={{ marginRight: "10px", flexGrow: 1 }}>
        <CustomInput
          type="number"
          name="salary"
          label="Salary (in USD)"
          id="report-salary"
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginRight: "10px",
          flexGrow: 1,
          marginTop: "13px",
        }}
      >
        <FormControl
          variant="standard"
          className="custom-input w-25 w-100 mt-4"
        >
          <Select
            id={duration}
            label="Duration"
            name="duration"
            onChange={handleChange}
            value={duration}
          >
            <MenuItem key="1" value="Daily">
              Daily
            </MenuItem>
            <MenuItem key="2" value="Weekly">
              Weekly
            </MenuItem>
            <MenuItem key="3" value="Monthly">
              Monthly
            </MenuItem>
            <MenuItem key="4" value="Annually">
              Annually
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
SalaryRow.propTypes = {
  handleChange: func.isRequired,
  duration: string,
};
