import React from 'react';
import CustomButton from '../../../../../components/CustomButton';
import { node, bool, func, string } from 'prop-types';
import { anonymousFunc } from '../../../../../utils/helpers';

const EditCard = ({
  includeDelete,
  allRequired,
  children,
  loading,
  handleSubmit,
  disable,
  handleDelete,
  deleteLoading,
  btnText,
  handleBtnClick,
  btnType,
}) => {
  return (
    <div className="edit-card bg-white rounded ml-4 py-4 px-5">
      {allRequired && <span className="co-lightred medium-text mb-1 d-block" style={{ paddingBottom: '10px' }}>All fields are required</span>}
      <form
        onSubmit={event => {
          event.preventDefault();
          if(!loading || !deleteLoading) {
            handleSubmit();
          }
        }}
        className="d-flex flex-column align-items-center"
      >
        {children}
        <div className="w-100 d-flex justify-content-end" style={{ paddingRight: '15px' }}>
          {includeDelete && <div className="w-25 mr-3">
            <CustomButton
              backgroundColor="#E24329"
              color="#fff"
              text="Delete"
              additionalClass="m-3"
              type="button"
              handleClick={handleDelete}
              loading={deleteLoading}
            />
          </div>}
          {!deleteLoading && <div className="w-25">
            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              text={btnText}
              additionalClass="m-3"
              loading={loading}
              disabled={disable}
              type={btnType}
              handleClick={handleBtnClick}
            />
          </div>}
        </div>
      </form>
    </div>
  );
};

EditCard.propTypes = {
  children: node.isRequired,
  loading: bool.isRequired,
  allRequired: bool,
  includeDelete: bool,
  handleSubmit: func,
  handleDelete: func,
  handleBtnClick: func,
  deleteLoading: bool,
  disable: bool,
  btnText: string,
  btnType: string,
};

EditCard.defaultProps = {
  allRequired: true,
  includeDelete: false,
  loading: false,
  deleteLoading: false,
  disable: false,
  btnText: 'Save',
  btnType: 'submit',
  handleSubmit: anonymousFunc,
  handleDelete: anonymousFunc,
  handleBtnClick: anonymousFunc,
};

export default EditCard;
