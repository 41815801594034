import React, { useEffect,useState } from "react";
import Card from "../../../../components/Card";
import Chart from "../../../../assets/images/Chart2.png";
import { Divider } from "@material-ui/core";
import CustomButton from "../../../../components/CustomButton";
import { getReferralStats } from "../../../../redux/actions/insightActions";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";

import ReactStars from "react-rating-stars-component";
import CustomTextArea from "../../../../components/CustomTextArea";
import { useHistory } from "react-router";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  getColleagues} from "../../../../redux/actions/recognitionAction";
import {
   createNominationAction,
   getAllPollsAction
} from "../../../../redux/actions/nominationAction";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import MultipleValueTextInput from 'react-multivalue-text-input';
import './nomination.css';
 

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
       fontSize: 18,
    },
  },
});

 

 


const ManageNomination = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isAdding, setisAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [colleague, setColleague] = useState(1);
  const [voteType, setVoteType] = useState("Employees");

   
  const [id, setID] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const {search_colleagues} = useSelector((store) => store);
  const {get_all_polls} = useSelector((store) => store);
  
  const [stateRecognition, setStateRecognition] = useState({
    title:"",
    start_date: "",
    end_date: "",
    nominees_id:"",
    others:""
   });
   const [searchColleagueInputData, setSearchColleagueInputData] = useState([])
 
  const classes = useStyles();
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  useEffect(() => {
    colleague === 1 ? setLoading(true) : setLoadingMore(true);
    Promise.resolve(getColleagues(dispatch, colleague)).finally(() =>
    colleague === 1 ? setLoading(false) : setLoadingMore(false)
    );
  }, [dispatch, colleague]);


  useEffect(() => {
    Promise.resolve(getAllPollsAction(dispatch, 1)).finally(() => setLoading(false) 
    );
  }, [dispatch, 1]);
  

  const handleChange = ({ target: { id, value, checked } }) => {
    setStateRecognition((prevState) => ({ ...prevState, [id]: value }));
  };
 

  useEffect(()=>{
    setSearchColleagueInputData(search_colleagues)
  },[dispatch,search_colleagues]);

 


  const handleSubmit = async (e) => {
  
    setLoading(true)
    e.preventDefault();
     
     const promise =  createNominationAction(dispatch, {
      ...stateRecognition,
    });
    Promise.resolve(promise).finally(() => {
          setLoading(false)
        /* setStateRecognition({
            title:"",
            start_date: "",
            end_date: "",
            nominees_id:"",
            others:""
           }); */

         // setTimeout( ()=>{
           // window.location.reload(false);
          //},2000)
         
    });

      
  
    
 
   };


 
 


  const handleChangeSelectType = ({ target: { value } }) => {
    setVoteType(value);
    //const skills = value;
    //setSelectedSkills(skills);
   };
 

 

 
  return (
    <Card additionalClass="mt-4 p-4">
     {!isAdding ?
     <div>
      <h5>Manage Polls</h5>
      <button className="btnc" 
      onClick={
        ()=>{
          setisAdding(true)
        }
      }
      >Create Award/Polls</button>  
      </div>
      :
      <h5>New Poll</h5>

        }
 
 

 
 
{!isAdding ?
              <Accordion>

{
 get_all_polls &&  get_all_polls.get_all_polls   &&
 get_all_polls.get_all_polls.map( (item,index)=>{
   return(
            <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton> 
               {item.title}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>Start Date: {item.start_date}</p>
              <p>End Date: {item.end_date}</p>

               <p><b>Nominees</b> </p>
               {
                 item.options.map((item2, index2)=>{
                   return(
                  <div>
                  <img src={item2.image_url} 
                  className="md-dd img-circle"
                 data-demo-src="https://i.pravatar.cc/20" data-user-popover="5" alt="" data-target="webuiPopover20" />
                  {item2.last_name} {item2.first_name}
                  <Divider/>
                  </div>
                   )
                 })
              

               }
              
             <br/>
            <div>
            
           {/* <button className="btn btn-primary"
          onClick={
        ()=>{
          //delete
         //setStateRecognition({ ...stateRecognition, request_id: item.recognition_id });
         //  approveAchievementPost();
           }
         }
      >Remove Nomination </button> */}
 
            </div>
            </AccordionItemPanel>
        </AccordionItem>
   )

 })

        
          
          
}
          


            </Accordion>

:
<div>
<form onSubmit={handleSubmit}> 


        <div className="mgt-top">
        <CustomInput
            type="text"
            placeholder="E.g Best employee of the year"
            label="Title"

            onChange={handleChange}
            value={stateRecognition.title}
            name="title"
            id="title"
          />
        </div>
        
        
        <div className="mgt-top">
        <label>Start Date</label>
        <input type="date" placeholder="Start Date" 
        className="form-control"
        onChange={handleChange}
        value={stateRecognition.start_date}
        name="start_date"
        id="start_date"
        />
       </div>

       <div className="mgt-top">
        <label>End Date</label>
        <input type="date" placeholder="End Date" 
        className="form-control"
        onChange={handleChange}
        value={stateRecognition.end_date}
        name="end_date"
        id="end_date"
        />
         </div>


        <div className="mgt-top">
        <CustomSelect
          handleChange={handleChangeSelectType}
          options={{
            0: "Select Type",
            "Employees": "Employees",
            "Others": "Others"
          }}
          id="nomineetypes"
          name="nomineetypes"
          label="Vote Type"
          value={voteType}
         />
        </div>
      
        <div className="mgt-top mgt-bottom">
           {
          voteType =="Employees" ?
          <div>
          <label>Select Nominees</label>
          <Autocomplete

          multiple           
          style={{ width: '100%'}}
          options={searchColleagueInputData.search_colleagues}
          classes={{
            option: classes.option,
          }}
          onChange={(event, newVSearchalue) => {
             let nominees_id = "";
            for(let i=0; i<newVSearchalue.length; i++)
            {
              nominees_id+=newVSearchalue[i].id+",";
            }
            setStateRecognition({ ...stateRecognition, nominees_id: nominees_id });

           }}

          getOptionLabel={(option) => option.email }
          renderOption={(option) => (
            <React.Fragment>
              {option.first_name == null ?"":option.first_name+" "} 
              {option.last_name == null ?"":option.last_name+" ("} 
              {option.email == null ?"":option.email+")"}     
              </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="outlined"
              style={{
                backgroundColor:'#fff'
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
          />
          </div>  
          :
          <div>
          <label>Others</label>
          <br/><br/>
          <MultipleValueTextInput
            onItemAdded={(item, allItems) => {
              console.log(`Item added: ${allItems}`)
              setStateRecognition({ ...stateRecognition, others: allItems });

            }
            }
            onItemDeleted={(item, allItems) =>{
               //console.log(`Item removed: ${allItems}`)
               setStateRecognition({ ...stateRecognition, others: allItems });

            }}
            label=""
            name="item-input"
            className="form-control paddingingx"
            placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
          />



          </div>
          
        }
          
         </div>
 


      <div>
        <CustomButton
            backgroundColor="#3A6ED4"
            color="#fff"
            text="Submit"
            type="submit"
            loading={loading}
           
          />


        <a className="centralised-text" onClick={
          ()=>{
              setisAdding(false)
          }
        }>
          Close 
          </a>
        </div>
</form>



</div>
}
     </Card>
  );
};

export default ManageNomination;
