/* eslint-disable no-self-assign */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cogoToast from 'cogo-toast';
import { bool, object as objectProptype } from 'prop-types';
import * as serviceWorker from '../../serviceWorker';
import './index.scss';


/**
 * @function ServiceWorkerWrapper
 * @description handles sw registration and update
 * @returns {JSX} - JSX
 */
const ServiceWorkerWrapper = ({ newUpdateAvailable, worker }) => {
  const [state, setState] = useState({
    newUpdate: newUpdateAvailable,
    waitingWorker: worker,
  });

  const dispatch = useDispatch();

  const { newUpdate, waitingWorker } = state;

  /**
   * @function onSWUpdate
   * @description triggers sw update
   * @param {object} registration - registration object
   * @returns {void}
   */
  const onSWUpdate = (registration) => {

    setState({
      newUpdate: true,
      waitingWorker: registration?.waiting,
    });
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
    const message = localStorage.getItem('sw-updated');
    if(message) {
      cogoToast.success(message, {
        hideAfter: 5,
        position: 'top-center'
      });
      localStorage.removeItem('sw-updated');
    }
  }, [dispatch]);

  /**
   * @function reloadPage
   * @description handles page reload
   * @returns {void}
   */
  const reloadPage = () => {
    localStorage.setItem('sw-updated', 'Application update was successful');
    if(waitingWorker) waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setState(prevState => ({ ...prevState, newUpdate: false }));

    // Reload page
    window.location.href = window.location.href;
  };

  return (
    <>
      {newUpdate && (
        <button
          type="button"
          className="sw-snack-bar"
          data-testid="sw-snack-bar"
          onClick={reloadPage}
          style={{ zIndex: '9'.repeat(100) }}
        >
        A new version is available!
          <span data-sw-reload="click here" />
        </button>
      )}
    </>
  );
};

ServiceWorkerWrapper.propTypes = {
  newUpdateAvailable: bool,
  worker: objectProptype,
};

ServiceWorkerWrapper.defaultProps = {
  newUpdateAvailable: false,
  worker: null,
};

export default ServiceWorkerWrapper;
