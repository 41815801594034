import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  object as objectPropType,
  array as arrayPropType,
  func,
  oneOfType,
} from "prop-types";
import Demo from "../demo";
import { RecruiterHold } from "../recruiter-hold";
import ErrorBoundary from "../components/ErrorBoundary";

/**
 * @function ProtectedRoute
 * @description - constructs the applications protected route layout
 * @returns {JSX} - JSX
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((store) => store.auth);

  const pathname = useLocation();
  // const history = useHistory();
  if (!isAuthenticated) {
    const accountType = localStorage.getItem("accountType");
    sessionStorage.setItem(
      "pageToVisit",
      JSON.stringify({
        accountType: accountType,
        path: pathname.pathname,
      })
    );
  }

  // useEffect(() => {
  //   if (pathname.pathname.includes("apply")) {
  //     console.log("Contains");
  //     localStorage.setItem("redirectTo", pathname.pathname);
  //   } else console.log("not contain");
  // }, []);

  return (
    <main>
      <ErrorBoundary>
        <Route
          {...rest}
          render={() =>
            isAuthenticated ? (
              // pathname.pathname.includes("apply") ? (
              //   <Route path={pathname.pathname} />
              // ) : (
              <Component redirectTo={pathname.pathname} />
            ) : (
              // )
              // )
              <Redirect to="/signin" from={pathname.pathname} />
            )
          }
        />
        ;
     {/*   <Demo /> */}
        <RecruiterHold />
      </ErrorBoundary>
    </main>
  );
};

ProtectedRoute.propTypes = {
  component: oneOfType([arrayPropType, objectPropType, func]).isRequired,
};

export default ProtectedRoute;
