import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import BackButton from "../../../components/BackButton";
import SubMenu from "../../../components/SubMenu";
import Layout from "../../../layout";
import MyAchievement from "./MyAchievement";
import AssignColleague from "./AssignColleague";
import Requests from "./Requests";
import MyAchievementHr from "./MyAchievement/hr";

import "./index.scss";
import { getReferralJobs } from "../../../redux/actions/jobActions";


 
const subMenuOptions = [
  ["My Accomplishment", "/recognition/my_achievement"],
  ["Recognise a colleague", "/recognition/assign_a_colleague"],
  ["Recognition Request", "/recognition/request"]
];

 
const subMenuOptionsAdmin = [
  ["Achievement Approval", "/recognition/hr/achievements_approval"],
  ["Recognition Request", "/recognition/request"]
];

 

  const Recognition = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const {
    userData: { user_type },
  } = useSelector((store) => store.auth);

  useEffect(() => {
    
    if (user_type === "1") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dispatch]);

  const displaySection = () => {
    if (pathname.includes("my_achievement")) {
      return <MyAchievement />;
    } 
   else if (pathname.includes("assign_a_colleague")) {
        return <AssignColleague />;
      } 
      else if (pathname.includes("achievements_approval")) {
          return <MyAchievementHr />;
      } 
    else {
      return <Requests />;
    }
  };
  return (
    <Layout>
      <div className="referrals d-flex mt-3">
        <div className="w-25">
          <BackButton />
          {
            isAdmin? 
            <SubMenu menuOptions={subMenuOptionsAdmin} />
            :
            <SubMenu menuOptions={subMenuOptions} />
          }
         </div>
        <div className="w-75 ml-4 d-flex align-items-center justify-content-center">
          {displaySection()}
        </div>
      </div>
    </Layout>
  );
};

export default Recognition;
