/* eslint-disable no-empty */
import React, { useState ,useRef} from "react"; 
import AuthWrapper from "../AuthWrapper";

const LinkedinCallback = () => {
 
  return (
    <div>
        <p>Processing.... Please Wait</p>
    </div>
  );
};

export default LinkedinCallback;
