import React, { useState , useEffect} from "react";

import CustomInput from "../../../components/CustomInput";
import SearchLocationInput from "../../../components/LocationComponent";
import CustomSelect from "../../../components/CustomSelect";
import CustomTextArea from "../../../components/CustomTextArea";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { func, object, string } from "prop-types";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  InputLabel
} from "@material-ui/core";
import { getCountryAction } from "../../../redux/actions/countryActions";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import icons from "../../../assets/icons/icon-collection.svg";

const JobDetails = ({
  handleChange,
  updateSelectedCountryID,
  industryOptions,
  levelOptions,
  skillOptions,
  setSkillOptions,
  setNewJob,
  newJob

}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedCountry,setSelectedCountry] = useState();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    country:selectedCountry || 0
  });

 const [skillsMultiSelect, setSkillMultiSelect ] = useState([{name: 'Option 1️⃣', id: 1},{name: 'Option 2️⃣', id: 2}])
  const addSkill = ({ target: { value } }) => {
    const skills = value;
    setSelectedSkills(skills);
    handleChange({ target: { value: skills, name: "skills_required" } });
  };

  const displaySkills = () => {
    return selectedSkills.map((skill, index) => (
      <span
        className="d-flex align-items-center justify-content-between mt-2 mr-2 py-1 pl-3 outlined"
        key={index.toString()}
      >
        {skill}
        <IconButton onClick={() => removeSkill(skill)}>
          <svg width="10" height="8">
            <use href={`${icons}#close-blue`}></use>
          </svg>
        </IconButton>
      </span>
    ));
  };

  const { country,profile } = useSelector(
    (store) => store
  );
  const { countries } = country;


  useEffect(() => {
    //dispatch({ type: CLEAR_CREATE_USER });
    const promises = [
      getCountryAction(dispatch),
    ];
  }, [dispatch]);

  const  renameKey =  ( obj, oldKey, newKey ) => {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }

  const handleChangeCountry = ({ target: { value } }) => {
    const countryID = value;
   setSelectedCountry(countryID);
   //alert(countryID);
   setState({ ...newJob, country: countryID });
   updateSelectedCountryID(countryID);
 };


  const removeSkill = (skillToRemove) => {
    const skillArray = [...selectedSkills];
    const newSkillArray = skillArray.filter((skill) => skill !== skillToRemove);
    setSelectedSkills(newSkillArray);
    handleChange({ target: { value: newSkillArray, name: "skills_required" } });
  };

  return (
    <>
    <div className="row">
    <div className="col-12 col-md-6">
    <CustomInput
        type="text"
        onChange={handleChange}
        name="campany_name"
        value={newJob.campany_name}
        placeholder=""
        label="Name Of Company"
        id="name"
      />
    </div>
    
    <div className="col-12 col-md-6">

    <FormControl variant="outlined" className="w-100 mb-4">
        <SearchLocationInput
          inputStyle="TextField"
          handleLocationChange={(e) =>
            handleChange({
              target: { value: e, name: "location" },
            })
          }
        />
      </FormControl>
    
    </div>
    
      
      {/*<CustomInput
        type="text"
        name="location"
        onChange={handleChange}
        value={newJob.location}
        label="Location"
        id="location"
      /> */}
 
   <div className="col-12 col-md-6">
    <InputLabel name="country-label">Select Country</InputLabel> 
     <Select
       labelId="country"
       name="country"
       label="country"
       value={state.country}
       onChange={handleChangeCountry}
       className="fullwidth-select">
       <MenuItem value="">Select a Country</MenuItem>
       {countries.map(({ name, id }) => (
         <MenuItem key={id} value={id}>
           {name}
         </MenuItem>
       ))}
     </Select>
    </div> 
    <div className="col-12 col-md-6">
      <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Staff Strength",
          "Sole Employee": "Sole Employee",
          "2-20": "2-20",
          "21-50": "21-50",
          "51-200": "51-200",
          "201-500": "201-500",
          "501-1000": "501-1000",
          "1,001-10,000": "1,001-10,000",
          "10,001-50,000": "10,001-50,000",
          "50,001+": "50,001+",
        }}
        id="no_of_staff"
        name="no_of_staff"
        label="Staff Strength"
        currentValue={newJob.no_of_staff}
      />
      </div>
      <div className="col-12 col-md-6">
      <CustomInput
        type="text"
        onChange={handleChange}
        name="role_title"
        value={newJob.role_title}
        label="Role title"
        id="role"
      />
      </div>
      <div className="col-12 col-md-6">
      <CustomInput
        type="text"
        onChange={handleChange}
        name="reports_to"
        value={newJob.reports_to}
        label="Reports To"
        id="report"
      />
      </div>
      <div className="col-12 col-md-6">
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select Level", ...levelOptions }}
        id="level"
        name="job_level"
        label="Job Level"
        currentValue={newJob.job_level}
      />
      </div>
      <div className="col-12 col-md-6">
      <CustomSelect
        handleChange={handleChange}
        options={{
          0: "Select Type",
          "part-time": "Part-time",
          "full-time": "Full-time",
          contract: "Contract",
          internship: "Internship",
          volunteer: "Volunteer",
        }}
        id="job_type"
        name="job_type"
        label="Type of Employment"
        currentValue={newJob.job_type}
      />
      </div> 
      
      <div className="col-12 col-md-6">
      <CustomSelect
        handleChange={handleChange}
        options={{ 0: "Select an Industry", ...industryOptions }}
        id="industry"
        name="industry"
        label="Industry"
        currentValue={newJob.industry}
      />
      </div>
      <div className="col-12 col-md-12">
        <br/>
      {/*
      <CustomTextArea
        onChange={handleChange}
        name="about_company"
        value={newJob.about_company}
        label="About Company/Role"
        id="about"
      /> */}
      <label>About Company/Role</label>
          <CKEditor
                    editor={ ClassicEditor }
                    data={newJob.about_company}
                    config={ {
                      //toolbar: [ 'bold', 'italic' ]
                  } }
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                        setNewJob({ ...newJob, ...{ about_company: data } });
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> 
      </div>
      <div className="col-12 col-md-6">
      <SalaryRow
        handleChange={handleChange}
        salary={newJob.salary}
        duration={newJob.duration}
      />
      </div>
      {/* <CustomSelect
        name="skills_required"
        handleChange={handleChange}
        options={skillOptions}
        id="skills"
        label="Skills"
        currentValue={newJob.skills_required}
        multiple
      /> */}
      
      <FormControl variant="standard" className="custom-input w-25 w-100 mt-3">
        <label className="d-block mb-0">Skills</label>
         
          {/*  <Multiselect
          options={skillsMultiSelect} // Options to display in the dropdown
         // selectedValues={selectedSkills} // Preselected value to persist in dropdown
        //  onSelect={addSkill} // Function will trigger on select event
          //onRemove={addSkill} // Function will trigger on remove event
          /> */}

        
      <div className="col-12 col-md-6">
        <Select
          id="skills"
          label="skills"
          onChange={addSkill}
          value={selectedSkills}
          multiple={true}
          className="fullwidth-select"
        
        >
         {/* <MenuItem value=" ">
            <em>Add Skill (ex: Interior Design)</em>
          </MenuItem> */}
          {skillOptions &&
            skillOptions.filter &&
            skillOptions
              .filter(
                ({ title }) => !selectedSkills.find((val) => val === title)
              )
              .map(({ title, id }) => (
                <MenuItem key={id} value={title}>
                  {title}
                </MenuItem>
              ))}
        </Select> 
       
      </div>
        <div className="skills-count mt-3 w-100 d-flex align-items-center">
          <span className="blob rounded px-2 py-1 bg-green co-white">
            <b>{selectedSkills.length}</b>
          </span>
          <span className="ml-2 co-green medium-text">Skills count</span>
        </div>
        {selectedSkills[0] && (
          <div className="mt-5 w-100 d-flex flex-wrap">{displaySkills()}</div>
        )}
      </FormControl>
    
      <div className="col-12 col-md-12" style={{marginTop:'5.5%'}}>
      <RadioGroup
        className="mt-2"
        name="isCompanyRepresentative"
        value={newJob.isCompanyRepresentative}
        onChange={handleChange}
      >
        <label>Are you a company representative?</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>
      </div>
      <div className="col-12 col-md-12">
      <RadioGroup
        className="mt-2"
        name="canRecruiterRequest"
        value={newJob.canRecruiterRequest}
        onChange={handleChange}
      >
        <label>Allow recruiter reach out to you</label>
        <div className="d-flex">
          <FormControlLabel
            onChange={handleChange}
            value="1"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            onChange={handleChange}
            value="0"
            control={<Radio color="primary" />}
            label="No"
          />
        </div>
      </RadioGroup>
      </div>

    </div>
    </>
  );
};

JobDetails.propTypes = {
  newJob: object.isRequired,
  setSelectedSkills: func.isRequired,
  handleChange: func.isRequired,
  industryOptions: object.isRequired,
  levelOptions: object.isRequired,
  skillOptions: object.isRequired,
};

export default JobDetails;

const SalaryRow = ({ handleChange, duration }) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleBlur = (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "text";
    const stripped = text.replaceAll(",", "");
    const formatted = numberWithCommas(stripped);
    document.querySelector("#report-salary").value = formatted;
    handleChange({
      target: {
        value: text,
        name: "salary",
      },
    });
  };
  const handleFocus = async (e) => {
    const text = e.target.value ? e.target.value : "";
    document.querySelector("#report-salary").type = "number";
    const stripped = text.replaceAll(",", "");
    if (!isNaN(stripped))
      document.querySelector("#report-salary").value = stripped;

    handleChange({
      target: {
        value: stripped,
        name: "salary",
      },
    });
  };
  return (
    <div className="d-flex justify-content-between">
      <div style={{ marginRight: "10px", flexGrow: 1 }}>
        <CustomInput
          type="number"
          name="salary"
          label="Salary (in USD)"
          id="report-salary"
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginRight: "10px",
          flexGrow: 1,
          marginTop: "13px",
        }}
      >
        <FormControl
          variant="standard"
          className="custom-input w-25 w-100 mt-4"
        >
          <Select
            id={duration}
            label="Duration"
            name="duration"
            onChange={handleChange}
            value={duration}
          >
            <MenuItem key="1" value="Daily">
              Daily
            </MenuItem>
            <MenuItem key="2" value="Weekly">
              Weekly
            </MenuItem>
            <MenuItem key="3" value="Monthly">
              Monthly
            </MenuItem>
            <MenuItem key="4" value="Annually">
              Annually
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
SalaryRow.propTypes = {
  handleChange: func.isRequired,
  duration: string,
};
