import React from "react";
import clsx from "clsx";
import { string, func, bool, node } from "prop-types";
import "./index.scss";
import { anonymousFunc } from "../../utils/helpers";
import { ButtonLoader } from "../ButtonLoader";

const CustomButton = ({
  children,
  type,
  disabled,
  loading,
  color,
  text,
  icon,
  alt,
  backgroundColor,
  additionalClass,
  handleClick,
  borderColor,
  leftALign,
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={loading ? anonymousFunc : handleClick}
      style={{
        background: backgroundColor,
        color: color,
        borderColor: borderColor,
        alignItems: "center",
      }}
      className={clsx(
        "custom-button position-relative d-flex w-100 p-2 ",
        additionalClass,
        {
          "text-center align-items-center": !leftALign,
          "text-left": leftALign,
          "co-gray": disabled,
        }
      )}
    >
      {icon && (
        <span className="position-absolute pl-2">
          <img width="20" src={icon} alt={alt} />
        </span>
      )}
      <div
        className={clsx("w-100 fw-bold", {
          "text-center": !leftALign,
          "ml-3 text-left": leftALign,
        })}
      >
        {loading ? <ButtonLoader /> : text}
      </div>
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  borderColor: string,
  color: string,
  text: node.isRequired,
  icon: string,
  alt: string,
  backgroundColor: string,
  additionalClass: string,
  handleClick: func,
  type: string,
  leftALign: bool,
  disabled: bool,
  loading: bool,
  children: node,
};

CustomButton.defaultProps = {
  borderColor: "E8E9EB",
  leftALign: false,
  disabled: false,
  loading: false,
  handleClick: anonymousFunc,
  type: "button",
};

export default CustomButton;
